import React from 'react';
import { withTheme } from 'styled-components';
import { isMobile } from 'react-device-detect';
import { Tooltip } from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';

import Icon from '../icon/Icon';
import Loader from '../loader/Loader';
import { Theme } from '../../../theme';
import { numberToShortString } from '../../utils/textDecoration';
import NotificationAnnimation from './NotificationAnnimation';

import { Badge, Container, Main } from './style';

type Props = {
  color: string;
  iconName: string;
  onClick: (e?: any) => void;
  theme: Theme;
  badge?: number;
  disabled?: boolean;
  isbordered?: boolean;
  helpText?: string;
  loading?: boolean;
  size?: string;
  style?: React.CSSProperties;
};

const IconButton = (props: Props): JSX.Element => {
  const {
    color,
    iconName,
    onClick,
    theme,
    badge,
    disabled = false,
    isbordered = false,
    helpText,
    loading,
    size = '24px',
    style,
  } = props;

  const uniqueId = uuidv4();

  const getBadge = (badgeNumber: number) =>
    isMobile ? (
      <NotificationAnnimation />
    ) : (
      <Badge>{numberToShortString(badgeNumber)}</Badge>
    );

  return (
    <Main style={style} id="iconButton">
      {loading ? (
        <Loader size={size} />
      ) : (
        <Container
          color={color}
          data-tooltip-id={uniqueId}
          data-tooltip-hidden={disabled || isMobile}
          data-tooltip-content={helpText}
          disabled={disabled}
          id="iconButton"
          isBordered={isbordered}
          onClick={onClick}
        >
          {badge ? getBadge(badge) : null}
          <Icon name={iconName} size={size} color={color} />
          <Tooltip
            id={uniqueId}
            noArrow={true}
            opacity={1}
            place="bottom"
            delayShow={isbordered ? 50 : 0}
            delayHide={0}
            style={{
              backgroundColor: theme.invertedBackground,
              color: theme.invertedText,
              fontWeight: 'bold',
              zIndex: 1300,
            }}
          />
        </Container>
      )}
    </Main>
  );
};

export default withTheme(IconButton);
