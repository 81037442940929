import styled from 'styled-components';

export const Main = styled.div<{ disabled?: boolean }>`
  align-items: center;
  background-color: transparent;
  margin-bottom: 24px;
  max-width: 400px;
  width: 90% !important;
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
`;

export const Container = styled.div`
  align-items: center;
  background-color: transparent;
  border: ${(props) => `2px solid ${props.color}`};
  border-radius: 32px;
  display: flex;
  height: 38px;
  justify-content: space-around;
  max-width: 400px;
  padding: 2px 16px;
`;

export const SelectCore = styled.select`
  background: transparent;
  border: none;
  box-shadow: none;
  color: ${(props) => props.color};
  font-size: 16px;
  margin: 0;
  padding: 0;
  height: 100%;
  outline: none;
  text-align: left;
  padding: 0 16px;
  width: 100%;

  &:focus {
    border: none;
    box-shadow: none;
    outline: none;
  }
`;

export const Option = styled.option<{
  backgroundColor: string;
  color: string;
}>`
  background: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
`;

export const ErrorMassage = styled.p`
  color: ${(props) => props.color};
  margin: 8px 0 0 0;
  text-align: left;
  text-transform: none;
`;

export const InputHeader = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const InputHeaderText = styled.p`
  color: ${(props) => props.color};
  margin: 0;
`;
