export type Notifications = Notification[];

export enum NotificationType {
  BLOG = 'blog',
  PLANNING = 'planning',
  CUSTOM = 'custom',
  VERSION = 'newVersion',
  WELCOME = 'welcome',
}

export type Notification = {
  assignedMember: string;
  assignedContent?: string;
  date: number;
  isRead: number;
  messageEN: string;
  messageFR: string;
  type: NotificationType;
  uid: string;
};

export type NotificationBody = {
  messageEN: string;
  messageFR: string;
  type: NotificationType;
};
