import React, { ReactElement } from 'react';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Theme } from '../../../../theme';
import Loader from '../../../components/loader/Loader';
import { displayPhoneNumberClearly } from '../../../utils/textDecoration';
import { formatDate } from '../../../utils/date';
import TextButton from '../../../components/textButton/TextButton';
import { Role, User, Users } from '../../../types/users';
import { icons } from '../../../constants/icons';
import Icon from '../../../components/icon/Icon';
import AvatarWithText from '../../../components/avatarWithText/AvatarWithText';

import {
  Info,
  Mail,
  MemberCard,
  MemberInfo,
  InfoWithIcon,
  MainList,
} from '../style';

type Props = {
  loading: boolean;
  onDisableUserModalOpen: (user: User) => void;
  theme: Theme;
  users: Users;
};

const AdminUsersListLaptop = (props: Props): ReactElement => {
  const { loading, onDisableUserModalOpen, theme, users } = props;
  const { t } = useTranslation();

  if (loading) return <Loader size="48px" />;

  return (
    <MainList>
      {users
        .sort((a: User, b: User) =>
          a.lastName.localeCompare(b.lastName),
        )
        .map((user: User) => (
          <MemberCard key={`${user.uid}-row`}>
            <AvatarWithText
              size={'big'}
              userUid={user.uid}
              fullUserName={`${user.firstName} ${user.lastName}`}
              style={{ justifyContent: 'flex-start' }}
            />
            <MemberInfo>
              <InfoWithIcon>
                <Icon
                  color={theme.text}
                  size={'24px'}
                  name={icons.MAIL_OUTLINE}
                />
                <Mail
                  color={theme.text}
                  href={`mailto:${user.email}`}
                  key={`${user.uid}-mail`}
                >
                  {user?.email ?? '-'}
                </Mail>
              </InfoWithIcon>
              <InfoWithIcon>
                <Icon
                  color={theme.text}
                  size={'24px'}
                  name={icons.CALL_OUTLINE}
                />
                <Info key={`${user.uid}-phoneNumber`}>
                  {(user &&
                    displayPhoneNumberClearly(user.phoneNumber)) ??
                    '-'}
                </Info>
              </InfoWithIcon>
              <InfoWithIcon>
                <Icon
                  color={theme.text}
                  size={'24px'}
                  name={icons.PHONE_OUTLINE}
                />
                <Info key={`${user.uid}-smartphoneNumber`}>
                  {(user &&
                    displayPhoneNumberClearly(
                      user.smartphoneNumber,
                    )) ??
                    '-'}
                </Info>
              </InfoWithIcon>
              <InfoWithIcon>
                <Icon
                  color={theme.text}
                  size={'24px'}
                  name={icons.HOUR_GLASS_OUTLINE}
                />
                <Info key={`${user.uid}-memberSince`}>
                  {(user && formatDate(user.memberSince, 'yyyy')) ??
                    '-'}
                </Info>
              </InfoWithIcon>
              <InfoWithIcon>
                <Icon
                  color={theme.text}
                  size={'24px'}
                  name={icons.PERSON_CIRCLE_OUTLINE}
                />
                <Info key={`${user.uid}-role`}>
                  {user.role ?? '-'}
                </Info>
              </InfoWithIcon>
              <InfoWithIcon>
                <Icon
                  color={
                    user.isFirstConnection ? theme.text : theme.danger
                  }
                  size={'24px'}
                  name={icons.CHECKMARK_DONE_CIRCLE_OUTLINE}
                />
                <Info
                  key={`${user.uid}-finalization`}
                  color={
                    user.isFirstConnection ? theme.text : theme.danger
                  }
                >
                  {user.isFirstConnection
                    ? formatDate(user.isFirstConnection, 'dd/MM/yyyy')
                    : t('ADMIN.UNFINALIZED_ACCOUNT')}
                </Info>
              </InfoWithIcon>
              <InfoWithIcon>
                <Icon
                  color={user.disabled ? theme.danger : theme.text}
                  size={'24px'}
                  name={
                    user.disabled
                      ? icons.LOCK_CLOSED_OUTLINE
                      : icons.LOCK_OPEN_OUTLINE
                  }
                />
                {user.disabled ? (
                  <Info
                    key={`${user.uid}-disabled`}
                    color={theme.danger}
                  >
                    {formatDate(user.disabled, 'dd/MM/yyyy')}
                  </Info>
                ) : null}
                <TextButton
                  color={user.disabled ? theme.success : theme.danger}
                  disabled={
                    user.role === Role.ADMIN ||
                    user.role === Role.OFFICE
                  }
                  onClick={() => onDisableUserModalOpen(user)}
                  title={
                    user.disabled
                      ? t('COMMON.REACTIVATE')
                      : t('COMMON.DEACTIVATE')
                  }
                />
              </InfoWithIcon>
            </MemberInfo>
          </MemberCard>
        ))}
    </MainList>
  );
};

export default withTheme(AdminUsersListLaptop);
