import React, { ReactElement, useEffect, useState } from 'react';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useHistory, useLocation } from 'react-router-dom';

import { Theme } from '../../../theme';
import Loader from '../../components/loader/Loader';
import CustomPageTitle from '../../components/texts/CustomPageTitle';
import {
  deleteAllNotifications,
  deleteNotification,
  listenToNotifications,
  updateAllNotificationIsRead,
  updateNotificationIsRead,
} from '../../firebase/notifications';
import {
  Notification,
  Notifications as NotificationsType,
} from '../../types/notifications';
import { auth } from '../../firebase/connection';
import { useCurrentLanguage } from '../../utils/i18n';
import { Language } from '../../../i18n';
import CustomSubTitle from '../../components/texts/CustomSubTitle';
import { formatDate, formatDistanceDate } from '../../utils/date';
import { icons } from '../../constants/icons';
import IconButton from '../../components/iconButton/IconButton';
import { goToTargetContent } from '../../utils/notifications';
import TextIconButton from '../../components/textIconButton/TextIconButton';
import Paragraph from '../../components/paragraph/Paragraph';

import {
  Info,
  Main,
  NotficationFooterButtons,
  NotificationFooter,
  NotificationCard,
  NotificationsContainer,
  ReadStatus,
  HeaderButtonsContainer,
} from './style';

type Props = { theme: Theme };

const Notifications = (props: Props): ReactElement => {
  const { theme } = props;

  const { t } = useTranslation();
  const currentLanguage = useCurrentLanguage();
  const [user] = useAuthState(auth);
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] =
    useState<NotificationsType>([]);

  useEffect(() => {
    let notifs: any;

    try {
      notifs = listenToNotifications(
        user!.uid,
        (notifs: NotificationsType) => {
          setNotifications(notifs);
        },
      );
    } catch (e) {
      // TODO handleError func
    } finally {
      setLoading(false);
    }

    return () => {
      if (notifs) notifs();
    };
  }, [user]);

  const totalNumber = notifications.length;
  const unreadNotificationsNumber = notifications.filter(
    (notification) => !notification.isRead,
  ).length;

  const handleCheckNotification = async (notificationId: string) => {
    await updateNotificationIsRead(notificationId);
  };
  const handleCheckAllNotifications = async (
    assignedMemberId: string,
  ) => {
    await updateAllNotificationIsRead(assignedMemberId);
  };

  const handleDeleteNotification = async (notificationId: string) => {
    await deleteNotification(notificationId);
  };
  const handleDeleteAllNotification = async (
    assignedMemberId: string,
  ) => {
    await deleteAllNotifications(assignedMemberId);
  };

  if (loading) return <Loader size="48px" />;

  return (
    <Main>
      <CustomPageTitle
        color={theme.text}
        style={{ paddingBottom: 40 }}
      >
        {notifications.length
          ? t('NOTIFICATION.TITLE', {
              total: totalNumber,
              count: unreadNotificationsNumber,
            })
          : t('COMMON.NOTIFICATIONS')}
      </CustomPageTitle>
      {!notifications.length ? (
        <Paragraph style={{ textAlign: 'center', paddingTop: 40 }}>
          {t('NOTIFICATION.NO_NOTIFICATION')}
        </Paragraph>
      ) : (
        <NotificationsContainer>
          <HeaderButtonsContainer>
            <TextIconButton
              color={theme.text}
              iconName={icons.CHECKMARK_OUTLINE}
              onClick={() => handleCheckAllNotifications(user!.uid)}
              title={t('NOTIFICATION.MARK_ALL_AS_READ')}
            />
            <TextIconButton
              color={theme.danger}
              iconName={icons.TRASH_OUTLINE}
              onClick={() => handleDeleteAllNotification(user!.uid)}
              title={t('NOTIFICATION.DELETE_ALL')}
            />
          </HeaderButtonsContainer>
          {notifications.map((notification: Notification) => (
            <NotificationCard key={notification.uid}>
              {!notification.isRead ? <ReadStatus /> : null}
              <CustomSubTitle
                color={theme.text}
                style={{
                  fontWeight: notification.isRead ? 'normal' : 'bold',
                  alignSelf: 'flex-start',
                }}
              >
                {currentLanguage === Language.EN
                  ? notification.messageEN
                  : notification.messageFR}
              </CustomSubTitle>
              <NotificationFooter>
                <Info italic={true}>
                  {t('NOTIFICATION.DATE', {
                    distance: formatDistanceDate(notification.date),
                    date: formatDate(
                      notification.date,
                      'dd/MM/yyyy HH:mm',
                    ),
                  })}
                </Info>
                <NotficationFooterButtons>
                  {notification.assignedContent ? (
                    <IconButton
                      color={theme.text}
                      onClick={() => {
                        goToTargetContent(
                          notification.type,
                          notification.uid,
                          notification.isRead,
                          history,
                          location,
                          notification.assignedContent,
                        );
                      }}
                      iconName={icons.EYE_OUTLINE}
                      helpText={t('COMMON.SEE')}
                    />
                  ) : null}
                  <IconButton
                    color={theme.text}
                    disabled={!!notification.isRead}
                    onClick={() => {
                      handleCheckNotification(notification.uid);
                    }}
                    iconName={icons.CHECKMARK_CIRCLE_OUTLINE}
                    helpText={t('NOTIFICATION.MARK_AS_READ')}
                  />
                  <IconButton
                    color={theme.danger}
                    onClick={() => {
                      handleDeleteNotification(notification.uid);
                    }}
                    iconName={icons.TRASH_OUTLINE}
                    helpText={t('COMMON.DELETE')}
                  />
                </NotficationFooterButtons>
              </NotificationFooter>
            </NotificationCard>
          ))}
        </NotificationsContainer>
      )}
    </Main>
  );
};

export default withTheme(Notifications);
