import { validatePhoneNumber } from './regexValidation';

export const capitalizeFirstLetter = (text: string) =>
  text.toLowerCase().charAt(0).toUpperCase() + text.slice(1);

export const capitalizeFirstLetterForEachWord = (text: string) => {
  return text
    .toLowerCase()
    .split(' ')
    .map((s) => capitalizeFirstLetter(s))
    .join(' ');
};

export const displayPhoneNumberClearly = (
  phoneNumber?: string,
): string | null => {
  if (!phoneNumber) return null;
  if (!validatePhoneNumber(phoneNumber)) return null;

  const parsedPhoneNumber = phoneNumber.replace('+33', '0');
  const groups = parsedPhoneNumber.match(/.{1,2}/g);

  return groups ? groups.join('.') : null;
};

export const numberToShortString = (number: number) => {
  if (!number) return '';
  return number <= 9 ? number.toString() : '9+';
};
