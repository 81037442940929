import React from 'react';
import Lottie from 'react-lottie';

import * as animationData from '../../../assets/lottie/loader.json';
import { Main } from './style';

type Props = {
  description?: string;
  size: string;
  style?: React.CSSProperties;
};

const Loader = (props: Props): JSX.Element => {
  const { description, size, style } = props;
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <>
      <Main customSize={size}>
        <Lottie
          options={defaultOptions}
          height={size}
          width={size}
          isStopped={false}
          isPaused={false}
          style={style}
        />
      </Main>
      {description && <h4>{description}</h4>}
    </>
  );
};

export default Loader;
