import i18n, { Language } from '../../i18n';
import FR from '../../i18n/fr.json';
import EN from '../../i18n/en.json';
import { useEffect, useState } from 'react';

export const getTranslationFile = () =>
  i18n.language === Language.FR ? FR : EN;

export const language = (): string =>
  localStorage.getItem('i18nextLng') ?? Language.FR;

export const useCurrentLanguage = () => {
  const [currentLanguage, setCurrentLanguage] = useState<string>(
    Language.FR,
  );

  useEffect(() => {
    setCurrentLanguage(language());
  }, [language()]);

  return currentLanguage;
};
