import styled from 'styled-components';

import { device, size } from '../../constants/screenSizes';
import Legend from '../../components/texts/CustomLegend';

export const Main = styled.div`
  align-items: center;
  align-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @media ${device.laptop} {
    max-width: ${() => size.laptop}px;
  }
`;

export const Header = styled.div`
  align-items: center;
  align-content: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  margin-bottom: 40px;

  @media ${device.laptop} {
    flex-direction: row;
  }
`;

export const UserDataRow = styled.div<{
  color: string;
  isFirst?: boolean;
  isLast?: boolean;
}>`
  align-items: center;
  border: ${(props) => `2px solid ${props.color}`};
  display: inline-grid;
  grid-template-columns: 32px 250px 2fr 130px 130px 130px 70px 100px 160px;
  margin-top: -2px;
  padding: 8px 8px;
  white-space: nowrap;
  width: 100%;

  ${(props) =>
    props.isFirst &&
    `
    border-radius: 8px 8px 0 0;
  `}
  ${(props) =>
    props.isLast &&
    `
    border-radius: 0 0 8px 8px;
  `}
`;

export const BaseUserDataRow = styled(UserDataRow)`
  grid-template-columns: 1fr 1fr 2fr 150px 150px 150px 230px;
`;

export const Info = styled.p<{ color?: string; header?: boolean }>`
  color: ${(props) => props.color ?? props.theme.text};
  font-style: ${(props) => (props.header ? 'italic' : 'none')};
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: visible;
`;

export const Mail = styled(Legend)`
  margin: 0;
  padding: 0 4px 0 0;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;

  @media ${device.laptop} {
    padding: 0 0px 0 8px;
    text-align: left;
  }
`;

export const FirstMailGridCase = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
`;

export const MainList = styled.div`
  align-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const MemberCard = styled.div`
  align-items: flex-start;
  border-radius: 16px;
  background-color: ${(props) =>
    props.color ?? props.theme.classicButton};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 4px;
  margin: 16px 0;
  overflow: hidden;
  padding: 32px 24px;
  position: relative;

  @media ${device.tablet} {
    align-items: center;
    display: grid;
    gap: 32px;
    grid-template-columns: 55% 45%;
  }
`;

export const MemberInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const InfoWithIcon = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
