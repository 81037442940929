import styled from 'styled-components';

export const Main = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding-top: 16px;
`;

export const AvatarPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: ${(props) => props.color};
  border-radius: 50%;
`;

export const AvatarPlaceholderText = styled.h1`
  color: white;
  font-family: Roboto;
  margin: 0;
  padding: 0;
  text-align: center;
`;
