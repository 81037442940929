import React, { ReactElement } from 'react';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Theme } from '../../../../theme';
import Loader from '../../../components/loader/Loader';
import {
  capitalizeFirstLetterForEachWord,
  displayPhoneNumberClearly,
} from '../../../utils/textDecoration';
import { formatDate } from '../../../utils/date';
import TextButton from '../../../components/textButton/TextButton';
import { BaseUser, BaseUsers } from '../../../types/users';

import {
  BaseUserDataRow,
  Info,
  Main,
  FirstMailGridCase,
} from '../style';

type Props = {
  loading: boolean;
  sendFirstConnectionEmail: (user: any) => void;
  theme: Theme;
  baseUsers: BaseUsers;
};

const AdminBaseUsersListLaptop = (props: Props): ReactElement => {
  const { t } = useTranslation();
  const { baseUsers, loading, sendFirstConnectionEmail, theme } =
    props;

  if (loading) return <Loader size="48px" />;

  return (
    <Main>
      <BaseUserDataRow color={theme.text} isFirst={true}>
        <Info header={true}>{t('COMMON.FIRST_NAME')}</Info>
        <Info header={true}>{t('COMMON.LAST_NAME')}</Info>
        <Info header={true}>{t('COMMON.EMAIL')}</Info>
        <Info header={true}>{t('COMMON.PHONE')}</Info>
        <Info header={true}>{t('COMMON.MOBILE_PHONE')}</Info>
        <Info header={true}>{t('COMMON.MEMBER_SINCE')}</Info>
        <Info header={true}>{t('ADMIN.FIRST_CONNECTION_MAIL')}</Info>
      </BaseUserDataRow>
      {baseUsers
        .sort((a: any, b: any) =>
          a.lastName.localeCompare(b.lastName),
        )
        .map((user: BaseUser, index: number) => (
          <BaseUserDataRow
            key={`base-${user.uid}-row`}
            color={theme.text}
            isLast={index === baseUsers.length - 1}
          >
            <Info key={`${user.uid}-firstName`}>
              {capitalizeFirstLetterForEachWord(user.firstName) ??
                '-'}
            </Info>
            <Info key={`${user.uid}-lastName`}>
              {capitalizeFirstLetterForEachWord(user.lastName) ?? '-'}
            </Info>
            <Info key={`${user.uid}-mail`}>{user.email ?? '-'}</Info>
            <Info key={`${user.uid}-phoneNumber`}>
              {displayPhoneNumberClearly(user.phoneNumber) ?? '-'}
            </Info>
            <Info key={`${user.uid}-smartphoneNumber`}>
              {displayPhoneNumberClearly(user.smartphoneNumber) ??
                '-'}
            </Info>
            <Info key={`${user.uid}-memberSince`}>
              {formatDate(user.memberSince, 'yyyy') ?? '-'}
            </Info>
            <Info key={`${user.uid}-firstConnectionMail`}>
              {user.isWelcomeEmailSent ? (
                <FirstMailGridCase>
                  <p
                    style={{ padding: 0, margin: 0 }}
                  >{`✅ ${formatDate(
                    user.isWelcomeEmailSent,
                    'dd/MM/yyyy - HH:mm',
                  )}`}</p>
                  <TextButton
                    color={theme.warning}
                    onClick={() => sendFirstConnectionEmail(user)}
                    title={t('ADMIN.RESEND')}
                  />
                </FirstMailGridCase>
              ) : (
                <TextButton
                  color={theme.text}
                  onClick={() => sendFirstConnectionEmail(user)}
                  title={t('ADMIN.SEND')}
                />
              )}
            </Info>
          </BaseUserDataRow>
        ))}
    </Main>
  );
};

export default withTheme(AdminBaseUsersListLaptop);
