import { t } from 'i18next';

import { TOAST_LEVELS } from '../constants/toastLevels';
import { displayToast, ToastLevels } from './Toast';
import { errorCodes } from '../types/errors';

export const isEmailError = (error: string): boolean =>
  ['user', 'email'].some((el) => error.includes(el));

const getFormattedCode = (code: string): string => {
  if (!code) return '';
  for (const value of Object.values(errorCodes)) {
    if (code.toString().includes(value)) {
      return value;
    }
  }
  return code;
};

const actionOrToast = (
  message: string,
  action?: (text: string) => void | string,
) => {
  action
    ? action(message)
    : displayToast(TOAST_LEVELS.ERROR as ToastLevels, message);
};

export const handleFirebaseErrors = (
  code: string,
  action?: (text: string) => void | string,
): void => {
  const formattedCode = getFormattedCode(code);

  switch (formattedCode) {
    case errorCodes.InvalidEmail:
      actionOrToast(t('ERROR.INVALID_EMAIL_ADDRESS'), action);
      break;
    case errorCodes.EventCancelInfoRequired:
      actionOrToast(t('ADMIN.REASON_CANCELLATION_REQUIRED'), action);
      break;
    case errorCodes.EmptyEmail:
      actionOrToast(t('ERROR.MISSING_EMAIL_ADDRESS'), action);
      break;
    case errorCodes.EmptyPassword:
      actionOrToast(t('ERROR.MISSING_PASSWORD'), action);
      break;
    case errorCodes.EmptyFirstName:
      actionOrToast(t('ERROR.MISSING_FIRST_NAME'), action);
      break;
    case errorCodes.EmptyLastName:
      actionOrToast(t('ERROR.MISSING_LAST_NAME'), action);
      break;
    case errorCodes.SimilarEmail:
      actionOrToast(t('ERROR.SIMILAR_EMAIL_ERROR'), action);
      break;
    case errorCodes.SimilarPassword:
      actionOrToast(t('ERROR.SIMILAR_PASSWORD_ERROR'), action);
      break;
    case errorCodes.DifferentValidationPassword:
      actionOrToast(t('ERROR.NOT_SAME_PASSWORD_ERROR'), action);
      break;
    case errorCodes.EmptyPhoneNumber:
      actionOrToast(t('ERROR.MISSING_PHONE_NUMBER'), action);
      break;
    case errorCodes.InvalidPhoneNumber:
      actionOrToast(t('ERROR.INVALID_NUMBER'), action);
      break;
    case errorCodes.InvalidPassword:
      actionOrToast(t('ERROR.INVALID_PASSWORD'), action);
      break;
    case errorCodes.NotificationTypeEmpty:
      actionOrToast('Choisir un type valide', action); //TODO translate
      break;
    case errorCodes.NotificationFREmpty:
      actionOrToast('Message FR manquant', action); //TODO translate
      break;
    case errorCodes.NotificationENEmpty:
      actionOrToast('Message EN manquant', action); //TODO translate
      break;
    case errorCodes.WrongPassword:
      displayToast(
        TOAST_LEVELS.ERROR as ToastLevels,
        t('ERROR.INCORECT_PASSWORD'),
      );
      break;
    case errorCodes.NewMemberEmailAlreadyInUse:
      displayToast(
        TOAST_LEVELS.ERROR as ToastLevels,
        t('ERROR.NEW_MEMBER_EMAIL_ALREADY_IN_USE'),
      );
      break;
    case errorCodes.UserNotFound:
      displayToast(
        TOAST_LEVELS.ERROR as ToastLevels,
        t('ERROR.UNKNOWN_EMAIL_ADDRESS'),
      );
      break;
    case errorCodes.EventNotFound:
      displayToast(
        TOAST_LEVELS.ERROR as ToastLevels,
        t('ERROR.EVENT_NOT_FOUND'),
      );
      break;
    case errorCodes.PermissionDenied:
      displayToast(
        TOAST_LEVELS.ERROR as ToastLevels,
        t('ERROR.PERMISSION_DENIED'),
      );
      break;
    case errorCodes.UserDisabled:
      displayToast(
        TOAST_LEVELS.ERROR as ToastLevels,
        t('ERROR.USER_DISABLED'),
      );
      break;
    case errorCodes.InvalidUser:
      displayToast(
        TOAST_LEVELS.ERROR as ToastLevels,
        t('ERROR.INVALID_USER'),
      );
      break;
    case errorCodes.TooManyRequests:
      displayToast(
        TOAST_LEVELS.ERROR as ToastLevels,
        t('ERROR.TOO_MANY_ATTEMPTS'),
      );
      break;
    case errorCodes.AvatarFileOversize:
      displayToast(
        TOAST_LEVELS.ERROR as ToastLevels,
        t('ERROR.AVATAR_WEIGHT_ERROR_TOAST'),
      );
      break;
    case errorCodes.AvatarFileRequired:
      displayToast(
        TOAST_LEVELS.ERROR as ToastLevels,
        t('ERROR.MISSING_AVATAR_FILE'),
      );
      break;
    case errorCodes.AvatarFileUploadFail:
      displayToast(
        TOAST_LEVELS.ERROR as ToastLevels,
        t('ERROR.AVATAR_FAIL_TO_UPLOAD'),
      );
      break;
    case errorCodes.NotFoundDeleteArticleBlog:
      displayToast(
        TOAST_LEVELS.ERROR as ToastLevels,
        'Une erreur est survenue lors de la suppression: introuvable', // TODO translate
      );
      break;
    case errorCodes.NotFoundUpdateArticleBlog:
      displayToast(
        TOAST_LEVELS.ERROR as ToastLevels,
        "Une erreur est survenue lors de l'enregistrement: introuvable", // TODO translate
      );
      break;
    case errorCodes.BlogFileOversize:
      displayToast(
        TOAST_LEVELS.ERROR as ToastLevels,
        "L'image est trop lourde; limite à 2 MO", // TODO translate
      );
      break;
    default:
      displayToast(
        TOAST_LEVELS.ERROR as ToastLevels,
        `${t('ERROR.ERROR_OCCURRED')} ${code}`,
      );
  }
};
