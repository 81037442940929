import React, { ReactElement } from 'react';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useHistory, useLocation } from 'react-router-dom';

import { Theme } from '../../../theme';
import { headerSize } from '../../constants/screenSizes';
import CustomPageTitle from '../texts/CustomPageTitle';
import {
  Notification,
  Notifications,
} from '../../types/notifications';
import TextIconButton from '../textIconButton/TextIconButton';
import { icons } from '../../constants/icons';
import { formatDistanceDate } from '../../utils/date';
import IconButton from '../iconButton/IconButton';
import { useCurrentLanguage } from '../../utils/i18n';
import { Language } from '../../../i18n';
import CustomSubTitle from '../texts/CustomSubTitle';
import { goToTargetContent } from '../../utils/notifications';

import {
  Info,
  NotificationFooter,
  NotificationCard,
  ModalCore,
  NotificationCore,
  NotificationCards,
  ReadStatus,
  NotficationFooterButtons,
} from './style';

type Props = {
  checkNotification: (id: string) => void;
  deleteNotification: (id: string) => void;
  isNotificationsModalOpen: boolean;
  notifications: Notifications;
  onIsNotificationsModalClose: () => void;
  goToNotifications: () => void;
  theme: Theme;
};

const Header = (props: Props): ReactElement => {
  const {
    checkNotification,
    deleteNotification,
    isNotificationsModalOpen,
    notifications,
    onIsNotificationsModalClose,
    goToNotifications,
    theme,
  } = props;

  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const currentLanguage = useCurrentLanguage();

  const onNotificationCoreClick = (notification: Notification) => {
    if (notification.assignedContent) {
      goToTargetContent(
        notification.type,
        notification.uid,
        notification.isRead,
        history,
        location,
        notification.assignedContent,
      );
      onIsNotificationsModalClose();
    }
  };

  const customStyle = (): any => {
    return {
      content: {
        backgroundColor: theme.background,
        border: 'none',
        borderRadius: 0,
        bottom: 0,
        boxShadow: '-14px 10px 24px rgba(0, 0, 0, 0.6)',
        height: `calc(100vh - ${headerSize.style} - 50px)`,
        left: `calc(100vw - 432px - 15px})`,
        margin: 0,
        padding: 24,
        right: 0,
        top: `calc(${headerSize.style} + 1px)`,
        transform: 'none',
        transformOrigin: '400px 0px',
        width: 400,
      },
      overlay: {
        alignItems: 'center',
        backgroundColor: 'transparent',
        display: 'flex',
        inset: 0,
        justifyContent: 'center',
        margin: 0,
        padding: 0,
        position: 'fixed',
        zIndex: 1100,
      },
    };
  };

  return (
    <Modal
      isOpen={isNotificationsModalOpen}
      onRequestClose={onIsNotificationsModalClose}
      style={customStyle()}
      id="notificationModal"
    >
      <ModalCore>
        <CustomPageTitle
          color={theme.text}
          style={{ paddingBottom: 32, textAlign: 'center' }}
        >
          {t('COMMON.NOTIFICATIONS')}
        </CustomPageTitle>
        <NotificationCards>
          {!notifications.length ? (
            <Info style={{ textAlign: 'center' }}>
              {t('NOTIFICATION.NO_NOTIFICATION')}
            </Info>
          ) : (
            notifications.map((notification) => (
              <NotificationCard key={notification.uid}>
                {!notification.isRead ? <ReadStatus /> : null}
                <NotificationCore>
                  <CustomSubTitle
                    color={theme.text}
                    style={{
                      fontWeight: notification.isRead
                        ? 'normal'
                        : 'bold',
                    }}
                  >
                    {currentLanguage === Language.EN
                      ? notification.messageEN
                      : notification.messageFR}
                  </CustomSubTitle>
                </NotificationCore>
                <NotificationFooter>
                  <Info italic={true}>
                    {formatDistanceDate(notification.date)}
                  </Info>
                  <NotficationFooterButtons>
                    {notification.assignedContent ? (
                      <IconButton
                        color={theme.text}
                        helpText={'Aller voir'}
                        onClick={() =>
                          onNotificationCoreClick(notification)
                        }
                        iconName={icons.EYE_OUTLINE}
                      />
                    ) : null}
                    <IconButton
                      color={theme.text}
                      disabled={!!notification.isRead}
                      helpText={t('NOTIFICATION.MARK_AS_READ')}
                      onClick={(e) => {
                        checkNotification(notification.uid);
                      }}
                      iconName={icons.CHECKMARK_CIRCLE_OUTLINE}
                    />
                    <IconButton
                      color={theme.danger}
                      helpText={t('COMMON.DELETE')}
                      onClick={(e) => {
                        deleteNotification(notification.uid);
                      }}
                      iconName={icons.TRASH_OUTLINE}
                    />
                  </NotficationFooterButtons>
                </NotificationFooter>
              </NotificationCard>
            ))
          )}
        </NotificationCards>
        <TextIconButton
          color={theme.text}
          iconName={icons.LIST_OUTLINE}
          onClick={() => {
            onIsNotificationsModalClose();
            goToNotifications();
          }}
          title={t('NOTIFICATION.SEE_ALL_BUTTON')}
        />
      </ModalCore>
    </Modal>
  );
};

export default withTheme(Header);
