import React, { ReactElement, useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { listenUserInfo } from '../../firebase/user';
import { memberRoleTranslation } from '../../utils/role';
import { Role, User } from '../../types/users';
import TextIconButton from '../../components/textIconButton/TextIconButton';
import { Theme } from '../../../theme';
import { icons } from '../../constants/icons';
import { auth } from '../../firebase/connection';
import {
  capitalizeFirstLetterForEachWord,
  displayPhoneNumberClearly,
} from '../../utils/textDecoration';
import Loader from '../../components/loader/Loader';
import CustomTitle from '../../components/texts/CustomTitle';
import CustomPageTitle from '../../components/texts/CustomPageTitle';
import Lang from '../../components/lang/Lang';
import { formatDate } from '../../utils/date';
import AvatarModification from '../../components/avatar/AvatarModification';
import Icon from '../../components/icon/Icon';
import ChangePasswordModal from './ChangePasswordModal';
import ChangeEmailModal from './ChangeEmailModal';
import ChangeUserInfoModal from './ChangeUserInfoModal';
import { isDevEnv } from '../../utils/env';
import Switch from '../../components/switch/Switch';
import { displayToast, ToastLevels } from '../../utils/Toast';
import { TOAST_LEVELS } from '../../constants/toastLevels';
import { goTo, ROUTES } from '../../router/routes';

import {
  AccountContainer,
  AccountItem,
  Info,
  InfoWithIcon,
  Mail,
  Main,
} from './style';

type Props = {
  activeTheme: string;
  theme: Theme;
  themeToggler: () => void;
};

const Settings = (props: Props): ReactElement => {
  const [user] = useAuthState(auth);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const { activeTheme, theme, themeToggler } = props;

  const [userInfo, setUserInfo] = useState({} as User);
  const [loading, setLoading] = useState(true);
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [isChangeUserInfoModalOpen, setIsChangeUserInfoModalOpen] =
    useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);
  const [isChangeEmailModalOpen, setIsChangeEmailModalOpen] =
    useState(false);

  useEffect(() => {
    setLoading(true);

    const unsubscribe = listenUserInfo(user!.uid, (u) => {
      setUserInfo(u);
      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const onChangeUserInfoModalClose = () =>
    setIsChangeUserInfoModalOpen(false);
  const onChangeUserInfoModalOpen = () =>
    setIsChangeUserInfoModalOpen(true);

  const onChangePasswordModalClose = () =>
    setIsChangePasswordModalOpen(false);
  const onChangePasswordModalOpen = () =>
    setIsChangePasswordModalOpen(true);

  const onChangeEmailModalClose = () =>
    setIsChangeEmailModalOpen(false);
  const onChangeEmailModalOpen = () =>
    setIsChangeEmailModalOpen(true);

  const onChangeIsSwitchOn = () => {
    displayToast(TOAST_LEVELS.SUCCESS as ToastLevels, `ok`);
    setIsSwitchOn(!isSwitchOn);
  };

  const goToVersions = () =>
    goTo(location, history, ROUTES.MENU.SETTINGS.VERSIONS);

  const displaySwitchThemeButton = (): ReactElement => {
    return activeTheme === 'light' ? (
      <TextIconButton
        color={theme.text}
        iconColor={theme.warning}
        iconName={icons.SUN_OUTLINE}
        onClick={themeToggler}
        title={t('SETTINGS.LIGHT_MODE')}
      />
    ) : (
      <TextIconButton
        color={theme.text}
        iconColor={theme.info2}
        iconName={icons.MOON_OUTLINE}
        onClick={themeToggler}
        title={t('SETTINGS.DARK_MODE')}
      />
    );
  };

  if (loading) return <Loader size="48px" />;

  return (
    <Main id="settingPage">
      <CustomPageTitle color={theme.text}>
        {t('SETTINGS.MY_ACCOUNT')}
      </CustomPageTitle>
      <AccountContainer id="accountConatainer">
        <AccountItem id="avatarItem">
          <CustomTitle color={theme.text}>
            {t('COMMON.AVATAR')}
          </CustomTitle>
          <AvatarModification
            changeAvatarButon={{
              disabled: false,
              text: t('COMMON.MODIFY'),
            }}
            userUid={userInfo.uid}
          />
        </AccountItem>
        <AccountItem id="infoItem">
          <CustomTitle color={theme.text}>
            {t('SETTINGS.MY_INFO')}
          </CustomTitle>
          <InfoWithIcon>
            <Icon
              color={theme.text}
              size={'24px'}
              name={icons.PEOPLE_OUTLINE}
              style={{ paddingRight: 8 }}
            />
            <Info key={`${userInfo.uid}-phoneNumber`}>
              {capitalizeFirstLetterForEachWord(
                `${userInfo.firstName} ${userInfo.lastName}`,
              ) ?? '-'}
            </Info>
          </InfoWithIcon>
          <InfoWithIcon>
            <Icon
              color={theme.text}
              size={'24px'}
              name={icons.MAIL_OUTLINE}
              style={{ paddingRight: 8 }}
            />
            <Mail
              color={theme.text}
              href={`mailto:${userInfo.email}`}
              key={`${userInfo.uid}-mail`}
            >
              {userInfo.email ?? '-'}
            </Mail>
          </InfoWithIcon>
          <InfoWithIcon>
            <Icon
              color={theme.text}
              size={'24px'}
              name={icons.CALL_OUTLINE}
              style={{ paddingRight: 8 }}
            />
            <Info key={`${userInfo.uid}-phoneNumber`}>
              {displayPhoneNumberClearly(userInfo.phoneNumber) ?? '-'}
            </Info>
          </InfoWithIcon>
          <InfoWithIcon>
            <Icon
              color={theme.text}
              size={'24px'}
              name={icons.PHONE_OUTLINE}
              style={{ paddingRight: 8 }}
            />
            <Info key={`${userInfo.uid}-smartphoneNumber`}>
              {displayPhoneNumberClearly(userInfo.smartphoneNumber) ??
                '-'}
            </Info>
          </InfoWithIcon>
          <InfoWithIcon>
            <Icon
              color={theme.text}
              size={'24px'}
              name={icons.HOUR_GLASS_OUTLINE}
              style={{ paddingRight: 8 }}
            />
            <Info key={`${userInfo.uid}-memberSince`}>
              {formatDate(userInfo.memberSince, 'yyyy') ?? '-'}
            </Info>
          </InfoWithIcon>
          <InfoWithIcon>
            <Icon
              color={theme.text}
              size={'24px'}
              name={icons.PERSON_CIRCLE_OUTLINE}
              style={{ paddingRight: 8 }}
            />
            <Info key={`${userInfo.uid}-role`}>
              {memberRoleTranslation(userInfo.role) ?? '-'}
            </Info>
          </InfoWithIcon>
          <TextIconButton
            color={theme.text}
            iconName={icons.CREATE_OUTLINE}
            onClick={onChangeUserInfoModalOpen}
            title={t('COMMON.MODIFY')}
          />
        </AccountItem>
        <AccountItem id="statsItem">
          <CustomTitle color={theme.text}>
            {t('SETTINGS.STATS')}
          </CustomTitle>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <text
              style={{
                fontSize: 48,
                fontWeight: 'bold',
                color: theme.info,
              }}
            >
              {userInfo.nbOpenedSessions ?? 0}
            </text>
            <text>
              {t('SETTINGS.OPENED_SESSION', {
                count: userInfo.nbOpenedSessions ?? 0,
              })}
            </text>
          </div>
        </AccountItem>
      </AccountContainer>
      <CustomPageTitle color={theme.text}>
        {t('SETTINGS.TITLE')}
      </CustomPageTitle>
      <AccountContainer id="ParametersConatainer">
        <AccountItem id="displayItem">
          <CustomTitle color={theme.text}>
            {t('SETTINGS.DISPLAY')}
          </CustomTitle>
          {displaySwitchThemeButton()}
          {userInfo.role === Role.ADMIN ? <Lang /> : null}
        </AccountItem>
        <AccountItem id="securityItem">
          <CustomTitle color={theme.text}>
            {t('SETTINGS.SECURITY')}
          </CustomTitle>
          <TextIconButton
            color={theme.text}
            iconName={icons.MAIL_OUTLINE}
            onClick={onChangeEmailModalOpen}
            title={t('SETTINGS.CHANGE_EMAIL')}
          />
          <TextIconButton
            color={theme.text}
            iconName={icons.LOCK_CLOSED_OUTLINE}
            onClick={onChangePasswordModalOpen}
            title={t('SETTINGS.CHANGE_PASSWORD')}
          />
        </AccountItem>
        <AccountItem id="versionsItem">
          <CustomTitle color={theme.text}>
            {t('VERSIONS.VERSION', {
              version: process.env.REACT_APP_VERSION,
            })}
          </CustomTitle>
          <TextIconButton
            color={theme.text}
            iconName={icons.RECEIPT_OUTLINE}
            onClick={goToVersions}
            title={t('SETTINGS.SEE_VERSIONS_HISTORY')}
          />
        </AccountItem>
        {isDevEnv() ? (
          <AccountItem id="securityItem">
            <CustomTitle color={theme.text}>
              Recevoir une notification par mail quand
            </CustomTitle>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 16,
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <label>
                Un membre s'assigne à une séance du samedi
              </label>
              <Switch
                onChange={onChangeIsSwitchOn}
                disabled={true}
                checked={isSwitchOn}
                loading={false}
              />
            </div>
          </AccountItem>
        ) : null}
      </AccountContainer>

      {isChangeUserInfoModalOpen ? (
        <ChangeUserInfoModal
          isChangeUserInfoModalOpen={isChangeUserInfoModalOpen}
          closeChangeUserInfoModal={onChangeUserInfoModalClose}
          userId={userInfo.uid}
          phoneNumber={userInfo.phoneNumber}
          smartphoneNumber={userInfo.smartphoneNumber}
        />
      ) : null}
      <ChangeEmailModal
        isChangeEmailModalOpen={isChangeEmailModalOpen}
        closeChangeEmailModal={onChangeEmailModalClose}
      />
      <ChangePasswordModal
        isChangePasswordModalOpen={isChangePasswordModalOpen}
        closeChangePasswordModal={onChangePasswordModalClose}
      />
    </Main>
  );
};

export default withTheme(Settings);
