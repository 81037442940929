import React, { ReactElement } from 'react';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Theme } from '../../../theme';
import { memberRoleTranslation } from '../../utils/role';
import {
  capitalizeFirstLetterForEachWord,
  displayPhoneNumberClearly,
} from '../../utils/textDecoration';
import { formatDate } from '../../utils/date';
import Tag from '../../components/tag/Tag';
import { Users } from '../../types/users';

import { DataRow, Info, Mail, Main } from './style';

type Props = { users: Users; theme: Theme };

const MembersListLaptopView = (props: Props): ReactElement => {
  const { t } = useTranslation();
  const { users, theme } = props;

  return (
    <Main>
      {
        <DataRow color={theme.text} isFirst={true}>
          <Info header={true}>{t('COMMON.LAST_NAME')}</Info>
          <Info header={true}>{t('COMMON.EMAIL')}</Info>
          <Info header={true}>{t('COMMON.PHONE')}</Info>
          <Info header={true}>{t('COMMON.MOBILE_PHONE')}</Info>
          <Info header={true}>{t('COMMON.MEMBER_SINCE')}</Info>
          <Info header={true}>{t('COMMON.STATUS')}</Info>
        </DataRow>
      }
      {users.map((user, index: number) => (
        <DataRow
          key={`${user.uid}-row`}
          color={theme.text}
          isLast={index === users.length - 1}
        >
          <Tag
            color={theme.text}
            text={capitalizeFirstLetterForEachWord(
              `${user.firstName} ${user.lastName}`,
            )}
            userUid={user.uid}
          />
          <Mail
            color={theme.text}
            href={`mailto:${user.email}`}
            key={`${user.uid}-mail`}
          >
            {(user && user.email) || '-'}
          </Mail>
          <Info key={`${user.uid}-phoneNumber`}>
            {(user && displayPhoneNumberClearly(user.phoneNumber)) ||
              '-'}
          </Info>
          <Info key={`${user.uid}-smartphoneNumber`}>
            {(user &&
              displayPhoneNumberClearly(user.smartphoneNumber)) ||
              '-'}
          </Info>
          <Info key={`${user.uid}-memberSince`}>
            {(user && formatDate(user.memberSince, 'yyyy')) || '-'}
          </Info>
          <Info key={`${user.uid}-role`}>
            {(user && memberRoleTranslation(user.role)) || '-'}
          </Info>
        </DataRow>
      ))}
    </Main>
  );
};

export default withTheme(MembersListLaptopView);
