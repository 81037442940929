import React, { ReactElement, useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Theme } from '../../../theme';
import Loader from '../../components/loader/Loader';
import TextIconButton from '../../components/textIconButton/TextIconButton';
import CustomPageTitle from '../../components/texts/CustomPageTitle';
import { icons } from '../../constants/icons';
import { auth } from '../../firebase/connection';
import {
  adminReActivateEvent,
  listenToNextMonthEvents,
  suscribMemberOnSession,
} from '../../firebase/events';
import { Event, Events, EventStatus } from '../../types/event';
import { formatDate } from '../../utils/date';
import Alert from '../../components/alert/Alert';
import AssignUserToEventModal from './AssignUserToEventModal';
import { Role } from '../../types/users';
import { getUserRole } from '../../firebase/user';
import { ToastLevels, displayToast } from '../../utils/Toast';
import { TOAST_LEVELS } from '../../constants/toastLevels';
import AdminCancelEventModal from './AdminCancelEventModal';
import { handleFirebaseErrors } from '../../utils/errors';
import { useScrollToAnchor } from '../../utils/useScrollToAnchor';
import PlanningCard from './PlanningCard';

import { Main, SessionsContainer } from './style';

type Props = { theme: Theme };

const Planning = (props: Props): ReactElement => {
  const { theme } = props;

  const { t } = useTranslation();
  const [user] = useAuthState(auth);
  const { anchor } = useScrollToAnchor();

  const [events, setEvents] = useState<Events>([]);
  const [userRole, setUserRole] = useState<Role>('' as Role);
  const [loading, setLoading] = useState(true);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [
    isAssignUserToEventModalOpen,
    setIsAssignUserToEventModalOpen,
  ] = useState(false);
  const [
    isAdminCancelEventModalOpen,
    setIsAdminCancelEventModalOpen,
  ] = useState(false);
  const [currentEvent, setCurrentEvent] = useState<Event | null>(
    null,
  );

  useEffect(() => {
    let eventListener: any;

    const fetchData = async () => {
      setUserRole(await getUserRole(user!.uid));
    };

    fetchData();

    try {
      eventListener = listenToNextMonthEvents((ev) => {
        setEvents(ev);
      });
    } catch (e) {
      // TODO handleError func
    } finally {
      setLoading(false);
    }

    return () => {
      if (eventListener) eventListener();
    };
  }, []);

  useEffect(() => {
    if (currentEvent) {
      const updatedEvent = events.find(
        (event) => event.uid === currentEvent.uid,
      );
      if (
        !updatedEvent ||
        updatedEvent.status !== currentEvent.status ||
        updatedEvent.assignedMember !== currentEvent.assignedMember
      ) {
        onConfirmModalClose();
        onAssignUserToEventModalClose();
      }
    }
  }, [events, currentEvent]);

  const suscribeUserToEvent = async (id: string, date: number) => {
    try {
      await suscribMemberOnSession(id, date, user!.uid);
      document.body.style.overflow = 'inherit';
      displayToast(
        TOAST_LEVELS.SUCCESS as ToastLevels,
        t('PLANNING.SUSCRIBE_OK_TOAST'),
      );
    } catch (error: any) {
      handleFirebaseErrors(error.code || error.message);
    } finally {
      onConfirmModalClose();
    }
  };

  const onConfirmModalOpen = (event: Event) => {
    setCurrentEvent(event);
    setIsConfirmModalOpen(true);
  };

  const onConfirmModalClose = () => {
    setIsConfirmModalOpen(false);
    setCurrentEvent(null);
  };

  const onAssignUserToEventModalOpen = (event: Event) => {
    setCurrentEvent(event);
    setIsAssignUserToEventModalOpen(true);
  };

  const onAssignUserToEventModalClose = () => {
    setCurrentEvent(null);
    setIsAssignUserToEventModalOpen(false);
  };

  const onAdminCancelEventModalOpen = (event: Event) => {
    setCurrentEvent(event);
    setIsAdminCancelEventModalOpen(true);
  };

  const onAdminCancelEventModalClose = () => {
    setCurrentEvent(null);
    setIsAdminCancelEventModalOpen(false);
  };

  const isPassedEvent = (date: number) =>
    new Date(date * 1000 + 86400000) < new Date();

  const statusToDisplay = (
    status: EventStatus,
    date: number,
  ): { color: string; text: string } => {
    if (isPassedEvent(date)) {
      return {
        color: theme.off,
        text: t('COMMON.EVENT_STATUS.FINISHED'),
      };
    }

    switch (status) {
      case EventStatus.Validated:
        return {
          color: theme.success,
          text: t('COMMON.EVENT_STATUS.VALIDATED'),
        };
      case EventStatus.Canceled:
        return {
          color: theme.danger,
          text: t('COMMON.EVENT_STATUS.CANCELED'),
        };
      case EventStatus.Pending:
        return {
          color: theme.warning,
          text: t('COMMON.EVENT_STATUS.PENDING'),
        };
      default:
        return { color: theme.text, text: t('COMMON.UNKNOW') };
    }
  };

  function renderAssignButton(event: Event): ReactElement {
    const isUserAssigned = user?.uid !== event.assignedMember;
    const isEventCanceled = event.status === EventStatus.Canceled;
    const isEventPassed = isPassedEvent(event.date);

    return event.assignedMember ? (
      <TextIconButton
        disabled={isUserAssigned || isEventCanceled || isEventPassed}
        color={theme.text}
        iconName={icons.CREATE_OUTLINE}
        onClick={() => onAssignUserToEventModalOpen(event)}
        title={t('COMMON.MODIFY')}
      />
    ) : (
      <TextIconButton
        color={theme.text}
        disabled={isEventPassed || isEventCanceled}
        iconName={icons.PERSON_ADD_OUTLINE}
        onClick={() => onConfirmModalOpen(event)}
        title={t('PLANNING.ASSIGN')}
      />
    );
  }

  const reActivateEvent = async (event: Event) => {
    try {
      await adminReActivateEvent(
        event,
        !!event.assignedMember,
        user!.uid,
      );
      displayToast(
        TOAST_LEVELS.SUCCESS as ToastLevels,
        t('PLANNING.REACTIVATED_OK_TOAST'),
      );
    } catch (error: any) {
      handleFirebaseErrors(error.code || error.message);
    }
  };

  const renderOfficeAdminEventManagingButton = (
    event: Event,
  ): ReactElement | null => {
    const isMember = userRole === Role.MEMBER;
    const isEventCanceled = event.status === EventStatus.Canceled;

    if (isMember) {
      return null;
    }
    if (isEventCanceled) {
      return (
        <TextIconButton
          color={theme.success}
          iconName={icons.CHECKMARK_OUTLINE}
          onClick={() => reActivateEvent(event)}
          title={t('PLANNING.REOPEN_EVENT')}
          disabled={isPassedEvent(event.date)}
        />
      );
    }
    return (
      <TextIconButton
        color={theme.danger}
        iconName={icons.TRASH_OUTLINE}
        onClick={() => onAdminCancelEventModalOpen(event)}
        title={t('PLANNING.CANCEL_EVENT')}
        disabled={isPassedEvent(event.date)}
      />
    );
  };

  return (
    <Main>
      <CustomPageTitle
        color={theme.text}
        style={{ paddingBottom: 40 }}
      >
        {t('PLANNING.TITLE')}
      </CustomPageTitle>
      <SessionsContainer>
        {loading ? (
          <Loader size="48px" />
        ) : (
          events.map((event) => (
            <PlanningCard
              anchor={anchor}
              event={event}
              key={event.uid}
              renderAssignButton={renderAssignButton}
              renderOfficeAdminEventManagingButton={
                renderOfficeAdminEventManagingButton
              }
              statusToDisplay={statusToDisplay}
            />
          ))
        )}
      </SessionsContainer>
      {currentEvent ? (
        <Alert
          button={{
            color: theme.success,
            iconName: icons.CHECKMARK_OUTLINE,
            text: t('COMMON.CONFIRM'),
            action: () =>
              suscribeUserToEvent(
                currentEvent.uid,
                currentEvent.date,
              ),
          }}
          closeAction={() => onConfirmModalClose()}
          ismodalOpen={isConfirmModalOpen}
          message={t('PLANNING.CONFIRM_ALERT_DESCRIPTION', {
            date: formatDate(currentEvent.date, 'dd/MM/yyyy'),
          })}
          secondaryButton={{
            color: theme.danger,
            iconName: icons.CLOSE_OUTLINE,
            text: t('COMMON.CANCEL'),
            action: () => onConfirmModalClose(),
          }}
          title={t('PLANNING.CONFIRM_ALERT_TITLE')}
        />
      ) : null}
      {currentEvent && user ? (
        <AssignUserToEventModal
          isAssignUserToEventModalOpen={isAssignUserToEventModalOpen}
          closeAssignUserToEventModal={onAssignUserToEventModalClose}
          currentEvent={currentEvent}
          userRole={userRole}
          userUid={user.uid}
        />
      ) : null}
      {currentEvent && user ? (
        <AdminCancelEventModal
          isAssignUserToEventModalOpen={isAdminCancelEventModalOpen}
          currentEvent={currentEvent}
          closeAdminCancelEventModal={onAdminCancelEventModalClose}
          userId={user.uid}
          userRole={userRole}
        />
      ) : null}
    </Main>
  );
};

export default withTheme(Planning);
