import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  runTransaction,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

import { db } from './connection';
import {
  NotificationBody,
  Notifications,
} from '../types/notifications';
import { getTimestampFromStrDate } from '../utils/date';
import { getAllUsersUid } from './user';
import { isDevEnv } from '../utils/env';

export const listenToNotifications = (
  userId: string,
  callback: (notifications: Notifications) => void,
  max?: number,
) => {
  try {
    const eventsQuery = max
      ? query(
          collection(db, 'notifications'),
          where('date', '!=', null),
          where('assignedMember', '==', userId),
          limit(max),
          orderBy('date', 'desc'),
        )
      : query(
          collection(db, 'notifications'),
          where('date', '!=', null),
          where('assignedMember', '==', userId),
          orderBy('date', 'desc'),
        );

    const unsubscribe = onSnapshot(eventsQuery, (querySnapshot) => {
      let notifications: Notifications = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        notifications.push({
          assignedMember: data.assignedMember,
          assignedContent: data.assignedContent,
          date: data.date,
          isRead: data.isRead,
          messageEN: data.messageEN,
          messageFR: data.messageFR,
          type: data.type,
          uid: data.uid,
        });
      });

      callback(notifications);
    });

    return unsubscribe;
  } catch (error) {
    console.log('Error fetching notifications:', error); //TODO error
    callback([]);
  }
};

export const listenToNotificationsNumbers = (
  userId: string,
  callback: (nb: number) => void,
) => {
  try {
    const eventsQuery = query(
      collection(db, 'notifications'),
      where('assignedMember', '==', userId),
      where('isRead', '==', 0),
    );

    const unsubscribe = onSnapshot(eventsQuery, (querySnapshot) => {
      let notifications: string[] = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        notifications.push(data.uid);
      });

      callback(notifications.length);
    });

    return unsubscribe;
  } catch (error) {
    console.log('Error fetching notifications:', error); //TODO error
    callback(0);
  }
};

export const newNotification = async (
  notificationBody: NotificationBody,
  targetUserId?: string,
  triggeringUserId?: string,
  targetContentId?: string,
) => {
  try {
    await runTransaction(db, async (transaction) => {
      const notificationsCollection = collection(db, 'notifications');
      const userIds = await (async () => {
        if (targetUserId) return [targetUserId];
        if (isDevEnv()) return ['lDOhMpVmb0hyK5ojq2O4oh4mVt83']; //To not spam for all dev env users when testing
        return getAllUsersUid();
      })();

      userIds
        .filter((userId: string) => userId !== triggeringUserId)
        .forEach((userId: string) => {
          const customUid = uuidv4();
          const newNotificationRef = doc(
            notificationsCollection,
            customUid,
          );

          transaction.set(newNotificationRef, {
            assignedMember: userId,
            assignedContent: targetContentId ?? '',
            date: getTimestampFromStrDate(),
            isRead: 0,
            uid: customUid,
            ...notificationBody,
          });
        });
    });
  } catch (error: any) {
    throw new Error(error);
  }
};

export const updateNotificationIsRead = async (
  notificationId: string,
) => {
  try {
    const notificationRef = doc(db, 'notifications', notificationId);
    if (!notificationRef) return; //TODO throw

    await updateDoc(notificationRef, {
      isRead: getTimestampFromStrDate(),
    });
  } catch (error) {
    console.error('Error updating notification isRead value:', error); // TODO: handle error
  }
};

export const updateAllNotificationIsRead = async (
  assignedMemberId: string,
) => {
  try {
    const notificationsRef = collection(db, 'notifications');
    if (!notificationsRef) return; //TODO throw
    if (!assignedMemberId) return; //TODO throw

    const q = query(
      notificationsRef,
      where('assignedMember', '==', assignedMemberId),
      where('isRead', '==', 0),
    );

    const querySnapshot = await getDocs(q);

    const batch = writeBatch(db);

    querySnapshot.forEach((doc) => {
      const notificationRef = doc.ref;
      batch.update(notificationRef, {
        isRead: getTimestampFromStrDate(),
      });
    });

    await batch.commit();
  } catch (error) {
    console.error(
      'Error updating notifications isRead value:',
      error,
    ); // TODO: handle error
  }
};

export const deleteNotification = async (notificationId: string) => {
  try {
    const notificationRef = doc(db, 'notifications', notificationId);
    if (!notificationRef) return; //TODO throw

    await deleteDoc(notificationRef);
  } catch (error) {
    console.error('Error deleting notification:', error); // TODO: handle error
  }
};
export const deleteAllNotifications = async (
  assignedMemberId: string,
) => {
  try {
    const notificationsRef = collection(db, 'notifications');
    if (!notificationsRef) return; //TODO throw

    const q = query(
      notificationsRef,
      where('assignedMember', '==', assignedMemberId),
    );

    const querySnapshot = await getDocs(q);

    const batch = writeBatch(db);

    querySnapshot.forEach((doc) => {
      const notificationRef = doc.ref;
      batch.delete(notificationRef);
    });

    await batch.commit();
  } catch (error) {
    console.error('Error deleting notifications:', error); // TODO: handle error
  }
};
