import styled from 'styled-components';
import { device, size } from '../../constants/screenSizes';

export const Main = styled.div`
  align-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @media ${device.laptop} {
    max-width: ${() => size.laptop}px;
  }
`;

export const NotificationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
`;

export const HeaderButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;

  @media ${device.laptop}, ${device.tablet} {
    flex-direction: row;
  }
`;

export const NotificationCard = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.classicButton};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 24px;
  position: relative;
`;

export const NotificationFooter = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
  width: 100%;
`;

export const NotficationFooterButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const Info = styled.p<{ italic?: boolean }>`
  color: ${(props) => props.theme.text};
  font-style: ${(props) => (props.italic ? 'italic' : 'none')};
  margin: 0;
  max-width: 70%;
  text-align: left;
`;

export const ReadStatus = styled.div`
  background-color: ${(props) => props.theme.info};
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  width: 8px;
`;
