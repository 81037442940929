import {
  firebaseConfig,
  firebaseDevConfig,
  firebaseURL,
  firebaseURLDev,
} from '../../firebaseConfig';
import { isDevEnv } from './env';

export const getDatabaseKeyForEnv = () =>
  isDevEnv() ? firebaseDevConfig : firebaseConfig;

export const getFirebaseURLForEnv = () =>
  isDevEnv() ? firebaseURLDev : firebaseURL;
