import React from 'react';
import Lottie from 'react-lottie';

import * as animationData from '../../../assets/lottie/notification.json';

import { MobileBadge } from './style';

const SIZE = 24;

const NotificationAnnimation = (): JSX.Element => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <MobileBadge customSize={SIZE}>
      <Lottie
        isClickToPauseDisabled={true}
        options={defaultOptions}
        height={SIZE}
        width={SIZE}
        isStopped={false}
        isPaused={false}
      />
    </MobileBadge>
  );
};

export default NotificationAnnimation;
