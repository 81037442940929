export enum EventStatus {
  Canceled = 'canceled',
  Pending = 'pending',
  Validated = 'validated',
}
export type Events = Event[];

export type Event = {
  assignedMember: string;
  cancelInfo?: string;
  date: number;
  note: string;
  presentMembers: string[];
  status: EventStatus;
  simplifiedAssignedMember: string;
  theme: string;
  type: string;
  uid: string;
};
