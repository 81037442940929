import styled from 'styled-components';

import Paragraph from '../../components/paragraph/Paragraph';
import { device, size } from '../../constants/screenSizes';

export const Main = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  @media ${device.laptop} {
    max-width: ${() => size.laptop}px;
  }
`;

export const GridsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 40px;
`;

export const Grid = styled.div`
  align-items: flex-start;
  display: grid;
  grid-template-columns: 8px 72px 136px 136px auto;
  padding: 0px 8px 0px 0px;
  width: 100%;
`;

export const Line = styled.div`
  background-color: ${(props) => props.color};
  border-radius: 48px;
  height: 100%;
  width: 8px;
`;

export const Legends = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media ${device.laptop}, ${device.tablet} {
    flex-direction: row;
  }
`;

export const Legend = styled(Paragraph).attrs((props) => ({
  style: {
    border: `2px solid ${props.color}`,
    borderRadius: 48,
    padding: '0 8px 0 8px',
  },
}))``;

export const VersionCard = styled.div`
  align-items: left;
  background-color: ${(props) => props.theme.classicButton};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
  padding: 8px 16px 8px 24px;
  position: relative;
  text-justify: inter-word;
`;

export const VersionCardLine = styled.div`
  background-color: ${(props) => props.color};
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 8px;
`;
