import { ReactElement, useState } from 'react';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useAuthState } from 'react-firebase-hooks/auth';

import { Theme } from '../../../theme';
import { icons } from '../../constants/icons';
import EditionModal from '../../components/editionModal/EditionModal';
import Input from '../../components/input/Input';
import { validateEmail } from '../../utils/regexValidation';
import { auth, changeEmail } from '../../firebase/connection';
import { displayToast, ToastLevels } from '../../utils/Toast';
import { TOAST_LEVELS } from '../../constants/toastLevels';
import {
  handleFirebaseErrors,
  isEmailError,
} from '../../utils/errors';
import { errorCodes } from '../../types/errors';

type Props = {
  closeChangeEmailModal: () => void;
  isChangeEmailModalOpen: boolean;
  theme: Theme;
};

const ChangeEmailModal = (props: Props): ReactElement => {
  const [user] = useAuthState(auth);
  const { t } = useTranslation();
  const { closeChangeEmailModal, isChangeEmailModalOpen, theme } =
    props;

  const [loading, setLoading] = useState(false);
  const [inputFields, setInputFields] = useState({
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });
  const [isCurrentPasswordDisplayed, setIsCurrentPasswordDisplayed] =
    useState(false);

  const handleChange = (e: any) => {
    setErrors({
      ...errors,
      [e.target.name]: '',
    });
    setInputFields({
      ...inputFields,
      [e.target.name]: e.target.value,
    });
  };

  const close = () => {
    setInputFields({
      email: '',
      password: '',
    });
    setErrors({
      email: '',
      password: '',
    });
    closeChangeEmailModal();
  };

  const validateValues = (inputValues: {
    email: string;
    password: string;
  }) => {
    let err = {
      email: '',
      password: '',
    };

    if (!inputValues.password) {
      handleFirebaseErrors(
        errorCodes.EmptyPassword,
        (x) => (err.password = x),
      );
    }
    if (!inputValues.email) {
      handleFirebaseErrors(
        errorCodes.EmptyEmail,
        (x) => (err.email = x),
      );
    } else if (!validateEmail(inputValues.email)) {
      handleFirebaseErrors(
        errorCodes.InvalidEmail,
        (x) => (err.email = x),
      );
    } else if (inputValues.email === user?.email) {
      handleFirebaseErrors(
        errorCodes.SimilarEmail,
        (x) => (err.email = x),
      );
    }
    setErrors(err);

    return err;
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      if (
        Object.values(validateValues(inputFields)).every(
          (err) => err === null || err === '',
        )
      ) {
        await changeEmail(
          user!,
          inputFields.email,
          inputFields.password,
        );
        setInputFields({
          email: '',
          password: '',
        });
        close();
        displayToast(
          TOAST_LEVELS.SUCCESS as ToastLevels,
          t('SETTINGS.CHANGE_EMAIL_SUCCESS'),
        );
      }
    } catch (error: any) {
      handleFirebaseErrors(
        error.code || error.message,
        isEmailError(error.code || error.message)
          ? (x) => setErrors({ ...errors, email: x })
          : (x) => setErrors({ ...errors, password: x }),
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <EditionModal
      button={{
        color: theme.success,
        iconName: icons.CHECKMARK_OUTLINE,
        text: t('COMMON.CONFIRM'),
        action: onSubmit,
      }}
      closeAction={close}
      ismodalOpen={isChangeEmailModalOpen}
      loading={loading}
      secondaryButton={{
        color: theme.danger,
        iconName: icons.CLOSE_OUTLINE,
        text: t('COMMON.CANCEL'),
        action: close,
      }}
      title={t('SETTINGS.CHANGE_EMAIL')}
    >
      <Input
        color={theme.text}
        disabled={loading}
        error={errors.email}
        leftIconName={icons.LOCK_CLOSED_OUTLINE}
        onChange={handleChange}
        placeholder={t('COMMON.EMAIL')}
        title={t('SETTINGS.NEW_EMAIL')}
        required={true}
        value={inputFields.email}
        name="email"
        type="email"
      />
      <Input
        color={theme.text}
        disabled={loading}
        error={errors.password}
        leftIconName={icons.LOCK_CLOSED_OUTLINE}
        onChange={handleChange}
        rightIcon={{
          action: () =>
            setIsCurrentPasswordDisplayed(
              !isCurrentPasswordDisplayed,
            ),
          helpText: isCurrentPasswordDisplayed ? 'Cacher' : 'Voir',
          name: isCurrentPasswordDisplayed
            ? icons.EYE_OUTLINE
            : icons.EYE_OFF_OUTLINE,
        }}
        placeholder={t('COMMON.PASSWORD')}
        required={true}
        title={t('COMMON.PASSWORD')}
        type={isCurrentPasswordDisplayed ? 'text' : 'password'}
        value={inputFields.password}
        name="password"
      />
    </EditionModal>
  );
};

export default withTheme(ChangeEmailModal);
