import React, { ReactElement } from 'react';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Theme } from '../../../theme';
import Paragraph from '../../components/paragraph/Paragraph';
import { capitalizeFirstLetterForEachWord } from '../../utils/textDecoration';
import { formatDate } from '../../utils/date';
import { BREAKING_CHANGE } from '../../constants/version';
import { Version } from '../../types/version';

import { VersionCard, VersionCardLine } from './style';

type Props = {
  empty: () => string;
  getColor: (key: string) => string;
  getVersion: (key: string) => string;
  versionKey: string;
  theme: Theme;
  version: Version;
};

const VersionsMobile = ({
  empty,
  getColor,
  getVersion,
  versionKey,
  theme,
  version,
}: Props): ReactElement => {
  const { t } = useTranslation();

  return (
    <VersionCard key={version.DESCRIPTION}>
      <VersionCardLine color={getColor(versionKey)} />
      <Paragraph style={{ padding: 0, fontWeight: 'bold' }}>
        {t('VERSIONS.VERSION', {
          version: getVersion(versionKey),
        })}
      </Paragraph>
      <Paragraph style={{ padding: 0, fontWeight: 'bold' }}>
        {capitalizeFirstLetterForEachWord(
          version.DATE
            ? formatDate(new Date(version.DATE), 'MMMM yyyy')
            : empty(),
        )}
      </Paragraph>
      <div>
        <Paragraph style={{ padding: 0 }}>
          {t(version.DESCRIPTION)}
        </Paragraph>
        {version.FEATURES && (
          <ul>
            {Object.values(version.FEATURES).map(
              (feature: string, index: number) => {
                return (
                  <li
                    style={{
                      color: Object.keys(version.FEATURES!)[
                        index
                      ].includes(BREAKING_CHANGE)
                        ? theme.danger
                        : theme.text,
                    }}
                    key={feature}
                  >
                    {t(feature)}
                  </li>
                );
              },
            )}
          </ul>
        )}
      </div>
    </VersionCard>
  );
};

export default withTheme(VersionsMobile);
