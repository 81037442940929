import { ReactElement, useState } from 'react';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Theme } from '../../../theme';
import { icons } from '../../constants/icons';
import EditionModal from '../../components/editionModal/EditionModal';
import Input from '../../components/input/Input';
import { formatDate } from '../../utils/date';
import { Event } from '../../types/event';
import { ToastLevels, displayToast } from '../../utils/Toast';
import { TOAST_LEVELS } from '../../constants/toastLevels';
import { Role } from '../../types/users';
import { adminCancelEvent } from '../../firebase/events';
import { handleFirebaseErrors } from '../../utils/errors';
import { errorCodes } from '../../types/errors';

type Props = {
  closeAdminCancelEventModal: () => void;
  currentEvent: Event | null;
  isAssignUserToEventModalOpen: boolean;
  userId: string;
  userRole: Role;
  theme: Theme;
};

const AdminCancelEventModal = (props: Props): ReactElement => {
  const { t } = useTranslation();
  const {
    closeAdminCancelEventModal,
    currentEvent,
    isAssignUserToEventModalOpen,
    userId,
    userRole,
    theme,
  } = props;

  const [inputFields, setInputFields] = useState({
    cancelInfo: currentEvent!.cancelInfo,
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    cancelInfo: '',
  });

  const handleChange = (e: any) => {
    setErrors({
      ...errors,
      [e.target.name]: '',
    });
    setInputFields({
      ...inputFields,
      [e.target.name]: e.target.value,
    });
  };

  const close = () => {
    setErrors({
      cancelInfo: '',
    });
    setInputFields({
      cancelInfo: '',
    });
    closeAdminCancelEventModal();
  };

  const validateValues = (inputValues: {
    cancelInfo: string | undefined;
  }) => {
    let err = {
      cancelInfo: '',
    };
    if (!inputValues.cancelInfo) {
      handleFirebaseErrors(
        errorCodes.EventCancelInfoRequired,
        (x) => (err.cancelInfo = x),
      );
    }

    setErrors(err);

    return err;
  };

  const cancelEvent = async () => {
    setLoading(true);
    try {
      if (
        Object.values(validateValues(inputFields)).every(
          (err) => err === null || err === '',
        )
      ) {
        await adminCancelEvent(
          currentEvent!,
          inputFields.cancelInfo ?? '',
          userId,
          userRole,
        );
        close();
        displayToast(
          TOAST_LEVELS.SUCCESS as ToastLevels,
          t('PLANNING.CANCEL_OK_TOAST'),
        );
      }
    } catch (error: any) {
      handleFirebaseErrors(error.code || error.message, (x) =>
        setErrors({ ...errors, cancelInfo: x }),
      );
      close();
    } finally {
      setLoading(false);
    }
  };

  return (
    <EditionModal
      button={{
        color: theme.success,
        iconName: icons.CHECKMARK_OUTLINE,
        text: t('COMMON.SAVE'),
        action: cancelEvent,
      }}
      closeAction={close}
      ismodalOpen={isAssignUserToEventModalOpen}
      loading={loading}
      secondaryButton={{
        color: theme.danger,
        iconName: icons.CLOSE_OUTLINE,
        text: t('COMMON.CANCEL'),
        action: close,
      }}
      title={t('PLANNING.SESSION_DATE', {
        date: formatDate(currentEvent!.date, 'dd/MM/yyyy'),
      })}
    >
      <Input
        color={theme.text}
        defaultValue={inputFields.cancelInfo}
        leftIconName={icons.DOCUMENT_TEXT_OUTLINE}
        charLimit={300}
        error={errors.cancelInfo}
        onChange={handleChange}
        placeholder={t('PLANNING.CANCEL_PLACEHOLDER')}
        title={t('PLANNING.CANCEL')}
        isTextArea={true}
        required={true}
        type="text"
        value={inputFields.cancelInfo ?? ''}
        name="cancelInfo"
      />
    </EditionModal>
  );
};

export default withTheme(AdminCancelEventModal);
