import React, { ReactElement, useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useHistory, useLocation } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Theme } from '../../../theme';
import Input from '../../components/input/Input';
import Select from '../../components/select/Select';
import { Option } from '../../components/select/style';
import TextIconButton from '../../components/textIconButton/TextIconButton';
import { generateYearsBetween } from '../../utils/yearArray';
import { auth, changePassword } from '../../firebase/connection';
import { getUserInfo, userValidation } from '../../firebase/user';
import { icons } from '../../constants/icons';
import { goTo, ROUTES } from '../../router/routes';
import {
  validatePassword,
  validatePhoneNumber,
} from '../../utils/regexValidation';
import { capitalizeFirstLetterForEachWord } from '../../utils/textDecoration';
import CustomPageTitle from '../../components/texts/CustomPageTitle';
import CustomTitle from '../../components/texts/CustomTitle';
import { displayToast, ToastLevels } from '../../utils/Toast';
import { TOAST_LEVELS } from '../../constants/toastLevels';
import {
  getYearFromTimestamp,
  getTimestampFromStrDate,
} from '../../utils/date';
import { handleFirebaseErrors } from '../../utils/errors';
import AvatarModification from '../../components/avatar/AvatarModification';
import { errorCodes } from '../../types/errors';
import { newNotification } from '../../firebase/notifications';
import { CustomNotification } from '../../utils/notifications';

import { ItalicInfo, Main } from './style';

type Props = { theme: Theme };

const UserFinalization = (props: Props): ReactElement => {
  const history = useHistory();
  const location = useLocation();
  const [user] = useAuthState(auth);
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [isPasswordDisplayed, setIsPasswordDisplayed] =
    useState(false);
  const [isCurrentPasswordDisplayed, setIsCurrentPasswordDisplayed] =
    useState(false);
  const [
    isPasswordValidationDisplayed,
    setIsPasswordValidationDisplayed,
  ] = useState(false);

  const [inputFields, setInputFields] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    smartphoneNumber: '',
    memberSince: 0,
    currentPassword: '',
    password: '',
    passwordValidation: '',
  });
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    smartphoneNumber: '',
    memberSince: '',
    currentPassword: '',
    password: '',
    passwordValidation: '',
  });

  useEffect(() => {
    setLoading(true);

    getUserInfo(user?.uid)
      .then((u) => {
        setInputFields({
          ...inputFields,
          memberSince: getYearFromTimestamp(u.memberSince),
          firstName: u.firstName,
          lastName: u.lastName,
          phoneNumber: u.phoneNumber,
          smartphoneNumber: u.smartphoneNumber,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const memberSinceYear = generateYearsBetween(
    1984,
    new Date().getFullYear(),
  );

  const handleChange = (e: any) => {
    setErrors({
      ...errors,
      [e.target.name]: '',
    });
    setInputFields({
      ...inputFields,
      [e.target.name]: e.target.value,
    });
  };

  const validateValues = (inputValues: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    smartphoneNumber: string;
    memberSince: number;
    currentPassword: string;
    password: string;
    passwordValidation: string;
  }) => {
    let err = {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      smartphoneNumber: '',
      memberSince: '',
      currentPassword: '',
      password: '',
      passwordValidation: '',
    };
    if (!inputValues.firstName) {
      handleFirebaseErrors(
        errorCodes.EmptyFirstName,
        (x) => (err.firstName = x),
      );
    }
    if (!inputValues.lastName) {
      handleFirebaseErrors(
        errorCodes.EmptyLastName,
        (x) => (err.lastName = x),
      );
    }
    if (!inputValues.phoneNumber) {
      handleFirebaseErrors(
        errorCodes.EmptyPhoneNumber,
        (x) => (err.phoneNumber = x),
      );
    }
    if (!validatePhoneNumber(inputValues.phoneNumber)) {
      handleFirebaseErrors(
        errorCodes.InvalidPhoneNumber,
        (x) => (err.phoneNumber = x),
      );
    }
    if (!validatePhoneNumber(inputValues.smartphoneNumber)) {
      handleFirebaseErrors(
        errorCodes.InvalidPhoneNumber,
        (x) => (err.smartphoneNumber = x),
      );
    }
    if (!inputValues.passwordValidation) {
      handleFirebaseErrors(
        errorCodes.EmptyPassword,
        (x) => (err.passwordValidation = x),
      );
    }
    if (!inputValues.currentPassword) {
      handleFirebaseErrors(
        errorCodes.EmptyPassword,
        (x) => (err.currentPassword = x),
      );
    }
    if (!inputValues.password) {
      handleFirebaseErrors(
        errorCodes.EmptyPassword,
        (x) => (err.password = x),
      );
    } else if (!validatePassword(inputValues.password)) {
      handleFirebaseErrors(
        errorCodes.InvalidPassword,
        (x) => (err.password = x),
      );
    } else if (
      inputValues.password !== inputValues.passwordValidation
    ) {
      handleFirebaseErrors(
        errorCodes.DifferentValidationPassword,
        (x) => (err.passwordValidation = x),
      );
    } else if (inputValues.currentPassword === inputValues.password) {
      handleFirebaseErrors(
        errorCodes.SimilarPassword,
        (x) => (err.password = x),
      );
    }
    setErrors(err);

    return err;
  };

  const onSubmit = async () => {
    setErrors({
      firstName: '',
      lastName: '',
      phoneNumber: '',
      smartphoneNumber: '',
      memberSince: '',
      currentPassword: '',
      password: '',
      passwordValidation: '',
    });
    setLoading(true);
    try {
      if (
        Object.values(validateValues(inputFields)).every(
          (err) => err === null || err === '',
        )
      ) {
        await changePassword(
          user!,
          inputFields.currentPassword,
          inputFields.password,
        );
        await userValidation(user?.uid, {
          firstName: inputFields.firstName,
          lastName: inputFields.lastName,
          phoneNumber: inputFields.phoneNumber,
          smartphoneNumber: inputFields.smartphoneNumber,
          memberSince: getTimestampFromStrDate(
            `${inputFields.memberSince}-01-01`,
          ),
        });

        goTo(location, history, ROUTES.MENU.MAIN, true);
        displayToast(
          TOAST_LEVELS.SUCCESS as ToastLevels,
          t('USER_FINALIZATION.ACCOUNT_SUCCESSFULLY_CREATED'),
        );
        await newNotification(
          CustomNotification.getWelcomeBody(inputFields.firstName),
          user?.uid,
        );
      }
      setLoading(false);
    } catch (error: any) {
      handleFirebaseErrors(error.code || error.message, (err) =>
        setErrors({ ...errors, currentPassword: err }),
      );
      setLoading(false);
    }
  };

  return (
    <Main>
      <CustomPageTitle
        color={props.theme.text}
        style={{ paddingBottom: 40 }}
      >
        {t('USER_FINALIZATION.TITLE')}
      </CustomPageTitle>
      <CustomTitle color={props.theme.text}>
        {t('USER_FINALIZATION.INFO_TO_CHECK')}
      </CustomTitle>
      <Input
        color={props.theme.text}
        defaultValue={capitalizeFirstLetterForEachWord(
          inputFields.firstName,
        )}
        disabled={loading}
        error={errors.firstName}
        leftIconName={icons.PERSON_CIRCLE_OUTLINE}
        loading={loading}
        onChange={handleChange}
        required={true}
        placeholder={t('COMMON.FIRST_NAME')}
        style={{ marginTop: 32 }}
        title={t('COMMON.FIRST_NAME')}
        type="text"
        value={capitalizeFirstLetterForEachWord(
          inputFields.firstName,
        )}
        name="firstName"
      />
      <Input
        color={props.theme.text}
        defaultValue={capitalizeFirstLetterForEachWord(
          inputFields.lastName,
        )}
        disabled={loading}
        error={errors.lastName}
        leftIconName={icons.PERSON_CIRCLE_OUTLINE}
        loading={loading}
        onChange={handleChange}
        placeholder={t('COMMON.LAST_NAME')}
        required={true}
        title={t('COMMON.LAST_NAME')}
        type="text"
        value={capitalizeFirstLetterForEachWord(inputFields.lastName)}
        name="lastName"
      />
      <Input
        color={props.theme.text}
        defaultValue={inputFields.phoneNumber}
        disabled={loading}
        error={errors.phoneNumber}
        leftIconName={icons.CALL_OUTLINE}
        loading={loading}
        onChange={handleChange}
        placeholder={t('USER_FINALIZATION.PHONE_PLACEHOLDER')}
        required={true}
        title={t('COMMON.PHONE')}
        type="tel"
        value={inputFields.phoneNumber}
        name="phoneNumber"
      />
      <Input
        color={props.theme.text}
        defaultValue={inputFields.smartphoneNumber}
        disabled={loading}
        error={errors.smartphoneNumber}
        leftIconName={icons.PHONE_OUTLINE}
        loading={loading}
        onChange={handleChange}
        placeholder={t('USER_FINALIZATION.PHONE_PLACEHOLDER')}
        title={t('COMMON.MOBILE_PHONE')}
        type="text"
        value={inputFields.smartphoneNumber}
        name="smartphoneNumber"
      />
      <Select
        id="date-select"
        color={props.theme.text}
        disabled={loading}
        leftIconName={icons.HOUR_GLASS_OUTLINE}
        loading={loading}
        name="memberSince"
        onChange={handleChange}
        required={true}
        title={t('COMMON.MEMBER_SINCE')}
        style={{ marginBottom: 48 }}
        value={inputFields.memberSince}
      >
        {memberSinceYear.map((d) => (
          <Option
            backgroundColor={props.theme.background}
            color={props.theme.text}
            key={d.toString()}
            value={d.toString()}
          >
            {d}
          </Option>
        ))}
      </Select>
      <CustomTitle color={props.theme.text}>
        {t('COMMON.AVATAR')}
      </CustomTitle>
      <AvatarModification
        changeAvatarButon={{
          disabled: false,
          text: t('COMMON.MODIFY'),
        }}
        style={{ margin: '32px 0' }}
        userUid={user?.uid!}
      />
      <CustomTitle color={props.theme.text}>
        {t('USER_FINALIZATION.NEW_PASSWORD_SUBTILE')}
      </CustomTitle>
      <Input
        color={props.theme.text}
        disabled={loading}
        error={errors.currentPassword}
        leftIconName={icons.LOCK_CLOSED_OUTLINE}
        onChange={handleChange}
        rightIcon={{
          action: () =>
            setIsCurrentPasswordDisplayed(
              !isCurrentPasswordDisplayed,
            ),
          helpText: isCurrentPasswordDisplayed ? 'Cacher' : 'Voir',
          name: isCurrentPasswordDisplayed
            ? icons.EYE_OUTLINE
            : icons.EYE_OFF_OUTLINE,
        }}
        placeholder={t('USER_FINALIZATION.PASSWORD_PLACEHOLDER')}
        required={true}
        title={t('USER_FINALIZATION.CURRENT_PASSWORD')}
        type={isCurrentPasswordDisplayed ? 'text' : 'password'}
        value={inputFields.currentPassword}
        name="currentPassword"
        style={{ marginTop: 32 }}
      />
      <Input
        color={props.theme.text}
        disabled={loading}
        error={errors.password}
        leftIconName={icons.LOCK_CLOSED_OUTLINE}
        onChange={handleChange}
        rightIcon={{
          action: () => setIsPasswordDisplayed(!isPasswordDisplayed),
          helpText: isPasswordDisplayed ? 'Cacher' : 'Voir',
          name: isPasswordDisplayed
            ? icons.EYE_OUTLINE
            : icons.EYE_OFF_OUTLINE,
        }}
        placeholder={t('USER_FINALIZATION.PASSWORD_PLACEHOLDER')}
        title={t('USER_FINALIZATION.NEW_PASSWORD')}
        required={true}
        type={isPasswordDisplayed ? 'text' : 'password'}
        value={inputFields.password}
        name="password"
      />
      <Input
        color={props.theme.text}
        disabled={loading}
        error={errors.passwordValidation}
        leftIconName={icons.LOCK_CLOSED_OUTLINE}
        onChange={handleChange}
        rightIcon={{
          action: () =>
            setIsPasswordValidationDisplayed(
              !isPasswordValidationDisplayed,
            ),
          helpText: isPasswordValidationDisplayed ? 'Cacher' : 'Voir',
          name: isPasswordValidationDisplayed
            ? icons.EYE_OUTLINE
            : icons.EYE_OFF_OUTLINE,
        }}
        placeholder={t('USER_FINALIZATION.PASSWORD_PLACEHOLDER')}
        title={t('USER_FINALIZATION.CONFIRM_PASSWORD')}
        required={true}
        type={isPasswordValidationDisplayed ? 'text' : 'password'}
        value={inputFields.passwordValidation}
        name="passwordValidation"
      />
      <ItalicInfo>
        {t('USER_FINALIZATION.REQUIRED_FIELDS_WARNING')}
      </ItalicInfo>
      <TextIconButton
        color={props.theme.text}
        disabled={loading}
        iconName={icons.CHECKMARK_OUTLINE}
        loading={loading}
        onClick={onSubmit}
        title={t('COMMON.VALIDATE')}
        style={{ margin: '40px 0 8px 0' }}
      />
    </Main>
  );
};

export default withTheme(UserFinalization);
