import React, { ReactElement, useEffect, useState } from 'react';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { isBrowser, isMobile } from 'react-device-detect';

import CustomPageTitle from '../../../components/texts/CustomPageTitle';
import TextIconButton from '../../../components/textIconButton/TextIconButton';
import { icons } from '../../../constants/icons';
import { goTo, ROUTES } from '../../../router/routes';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';

import { BlogArticle, BlogArticles } from '../../../types/blog';
import {
  deleteBlogArticle,
  getAllPostedBlogArticles,
} from '../../../firebase/blog';
import { formatDate } from '../../../utils/date';
import Alert from '../../../components/alert/Alert';
import CustomTitle from '../../../components/texts/CustomTitle';
import { ToastLevels, displayToast } from '../../../utils/Toast';
import { TOAST_LEVELS } from '../../../constants/toastLevels';
import IconButton from '../../../components/iconButton/IconButton';
import { auth } from '../../../firebase/connection';
import Reader from '../../../components/editor/Reader';
import Tag from '../../../components/tag/Tag';
import { capitalizeFirstLetterForEachWord } from '../../../utils/textDecoration';
import Avatar from '../../../components/avatar/Avatar';
import Editor from '../../../components/editor/Editor';
import { commentEditorToolsConfig } from '../../../components/editor/CommentEditorToolsConfig';
import Input from '../../../components/input/Input';
import { Theme } from '../../../../theme';

import { Main } from './style';

type Props = { theme: Theme };

const Blog = (props: Props): ReactElement => {
  const { theme } = props;

  const history = useHistory();
  const location = useLocation();
  const { i18n, t } = useTranslation();
  const [user] = useAuthState(auth);

  const [articles, setArticles] = useState<BlogArticles>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isDeleteArticleModalOpen, setIsDeleteArticleModalOpen] =
    useState<Partial<BlogArticle> | false>(false);

  useEffect(() => {
    getAllPostedBlogArticles()
      .then((a) => {
        setArticles(
          a.map((a) => ({
            ...a,
            message: a.message.replaceAll(
              `color: rgb(40, 44, 52)`,
              `color: ${theme.text}`,
            ),
          })),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const goToArticleEdition = (article?: BlogArticle) => {
    goTo(
      location,
      history,
      ROUTES.BLOG.EDITION,
      false,
      article ?? null,
    );
  };

  const goToArticleDraft = () => {
    goTo(location, history, ROUTES.BLOG.DRAFT, false);
  };

  const deleteArticle = async (uuid: string): Promise<void> => {
    try {
      await deleteBlogArticle(uuid);
    } catch (error) {
      setIsDeleteArticleModalOpen(false);
      return;
    }
    setArticles(articles.filter((article) => article.uid !== uuid));
    displayToast(
      TOAST_LEVELS.SUCCESS as ToastLevels,
      'Suppression réussie',
    );
    setIsDeleteArticleModalOpen(false);
  };

  const isMenuAviable = () => isBrowser && user;

  const test = [
    {
      author: 'CSIgSqIgPEPgqT4L9XmV4BlSnXS2',
      simplifiedAuthor: 'vincent croayne',
      postedDate: 1697270696,
      message: 'Test commantaire',
      uid: '6sd45vs36g8746s5d4bvss6eg4f8s',
    },
    {
      author: 'CSIgSqIgPEPgqT4L9XmV4BlSnXS2',
      simplifiedAuthor: 'vincent croayne',
      postedDate: 1697270696,
      message:
        "Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte. Il n'a pas fait que survivre cinq siècles, mais s'est aussi adapté à la bureautique informatique, sans que son contenu n'en soit modifié. Il a été popularisé dans les années 1960 grâce à la vente de feuilles Letraset contenant des passages du Lorem Ipsum, et, plus récemment, par son inclusion dans des applications de mise en page de texte, comme Aldus PageMaker.",
      uid: '65w4dsf6s54g6s4gb6ed84gsd6r4g',
    },
  ];

  return (
    <>
      <Main>
        <CustomPageTitle
          color={theme.text}
          style={{ paddingBottom: 32, textAlign: 'center' }}
        >
          {'Blog'}
        </CustomPageTitle>
        {isMenuAviable() ? (
          <div style={{ display: 'flex', gap: 16 }}>
            <TextIconButton
              color={theme.text}
              iconName={icons.ADD_CIRCLE_OUTLINE}
              onClick={() => goToArticleEdition()}
              title={'Ecrire un article'}
              style={{ marginBottom: 40 }}
            />
            <TextIconButton
              color={theme.text}
              iconName={icons.READER_OUTLINE}
              onClick={() => goToArticleDraft()}
              title={'Acceder aux brouillons'}
              style={{ marginBottom: 40 }}
            />
          </div>
        ) : null}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 24,
          }}
        >
          {articles.map((article: BlogArticle) => (
            <div key={article.uid}>
              <div
                style={{
                  backgroundColor: theme.classicButton,
                  borderRadius: '8px 8px 0 0',
                  padding: 24,
                  color: theme.text,
                }}
              >
                <CustomTitle
                  color={theme.info}
                  style={{ paddingBottom: 24 }}
                >
                  {capitalizeFirstLetterForEachWord(article.title)}
                </CustomTitle>
                <Reader
                  data={JSON.parse(article.message)}
                  holder={article.uid}
                />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    flexWrap: 'wrap',
                    flexDirection: 'column',
                    gap: 16,
                  }}
                >
                  {isMenuAviable() && user!.uid === article.author ? (
                    <div
                      style={{
                        display: 'flex',
                        gap: 16,
                        alignItems: 'center',
                        paddingTop: 24,
                        flexWrap: 'wrap',
                      }}
                    >
                      <IconButton
                        isbordered={true}
                        color={theme.text}
                        iconName={icons.CREATE_OUTLINE}
                        onClick={() => goToArticleEdition(article)}
                      ></IconButton>
                      <IconButton
                        isbordered={true}
                        color={theme.danger}
                        iconName={icons.TRASH_OUTLINE}
                        onClick={() =>
                          setIsDeleteArticleModalOpen(article)
                        }
                      ></IconButton>
                    </div>
                  ) : null}
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 16,
                      flexWrap: 'wrap',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Tag
                      userUid={article.author}
                      color={theme.text}
                      key={`ArticleAuthor${article.simplifiedAuthor}`}
                      text={article.simplifiedAuthor}
                    />
                    <text>
                      {formatDate(
                        article.postedDate,
                        'dd/MM/yyyy - HH:mm',
                      )}
                    </text>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  borderRadius: '0 0 8px 8px',
                  padding: 24,
                  color: theme.text,
                  alignItems: 'center',
                  backgroundColor: theme.classicButton,
                }}
              >
                <CustomTitle
                  color={theme.text}
                  style={{ paddingBottom: 24 }}
                >
                  Commentaires
                </CustomTitle>
                <Editor
                  data={article}
                  holder={`comment${article.uid}`}
                  setArticle={() => null}
                  tools={commentEditorToolsConfig}
                />
                <div
                  style={{
                    display: 'flex',
                    backgroundColor: 'pink',
                    alignItems: 'center',
                  }}
                >
                  <Input
                    title={'Nom'}
                    color={theme.text}
                    placeholder={'Nom,Prénom,Pseudo'}
                    type={'text'}
                    value={''}
                    onChange={() => null}
                    name="name"
                  />
                  <TextIconButton
                    color={theme.text}
                    iconName={icons.SAVE_OUTLINE}
                    onClick={() => null}
                    title={'Poster'}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 24,
                    width: '80%',
                    paddingTop: 16,
                  }}
                >
                  {test.map((com) => (
                    <div
                      key={com.uid}
                      style={{
                        backgroundColor: theme.background,
                        borderRadius: 8,
                        padding: 16,
                        display: 'flex',
                        gap: 16,
                      }}
                    >
                      {!isMobile ? (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            alignContent: 'center',
                            justifyContent: 'center',
                            flex: 1,
                            gap: 8,
                          }}
                        >
                          <Avatar
                            size={'medium'}
                            userUid={com.author}
                            fullUserName={com.simplifiedAuthor}
                          />
                          <div style={{ textAlign: 'center' }}>
                            {capitalizeFirstLetterForEachWord(
                              com.simplifiedAuthor,
                            )}
                          </div>
                        </div>
                      ) : null}
                      <div
                        style={{
                          display: 'flex',
                          flex: 8,
                          flexDirection: 'column',
                          gap: 16,
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 8,
                            flexWrap: 'wrap',
                            justifyContent: 'flex-end',
                          }}
                        >
                          {isMobile ? (
                            <Tag
                              userUid={com.author}
                              color={theme.text}
                              id={com.uid}
                              key={`CommantaryAuthor${article.simplifiedAuthor}`}
                              text={article.simplifiedAuthor}
                            />
                          ) : null}
                          {formatDate(
                            com.postedDate,
                            'dd/MM/yyyy - HH:mm',
                          )}
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexGrow: 1,
                          }}
                        >
                          {com.message}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </Main>
      <Alert
        button={{
          color: theme.success,
          iconName: icons.CHECKMARK_OUTLINE,
          text: t('COMMON.CONFIRM'),
          action: () => {
            (async () => {
              await deleteArticle(isDeleteArticleModalOpen.uid);
            })();
          },
        }}
        closeAction={() => setIsDeleteArticleModalOpen(false)}
        ismodalOpen={!!isDeleteArticleModalOpen}
        message={'delete this article'}
        secondaryButton={{
          color: theme.danger,
          iconName: icons.CLOSE_OUTLINE,
          text: t('COMMON.CANCEL'),
          action: () => setIsDeleteArticleModalOpen(false),
        }}
        title={isDeleteArticleModalOpen.title}
      />
    </>
  );
};

export default withTheme(Blog);
