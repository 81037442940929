export enum Role {
  MEMBER = 'member',
  OFFICE = 'office',
  ADMIN = 'admin',
}
export type Users = User[];
export type BaseUsers = BaseUser[];

export type User = {
  creationTime: number;
  disabled: number;
  email: string;
  firstName: string;
  isFirstConnection: number;
  lastName: string;
  memberSince: number;
  nbOpenedSessions: number;
  phoneNumber: string;
  role: Role;
  smartphoneNumber: string;
  tmpUidNotToUse: string;
  uid: string;
};

export type BaseUser = {
  firstName: string;
  lastName: string;
  uid: string;
  memberSince: number;
  email: string;
  tmpPassword: string;
  isWelcomeEmailSent: number;
  phoneNumber?: string;
  smartphoneNumber?: string;
};
