export const ROUTES = {
  BLOG: {
    DRAFT: '/blog/draft',
    EDITION: '/blog/edition', //TODO remove draft and edition from public route
    MAIN: '/blog/all',
  },
  HOME: '/',
  LOGIN: '/login',
  MENU: {
    MAIN: '/menu',
    ADMIN_SETTINGS: '/menu/adminSettings',
    MEMBERS: '/menu/members',
    PLANNING: '/menu/planning',
    SETTINGS: {
      PROFIL: '/menu/settings',
      VERSIONS: '/menu/settings/versions',
    },
  },
  NOTIFICATIONS: '/notifications',
  RESET_PWD: '/reset-password',
  USER_FINALIZATION: '/userFinalization',
};

export const goTo = (
  location: any,
  history: any,
  route: string,
  replace?: boolean,
  params?: any,
) => {
  return (
    location.pathname !== route &&
    (replace
      ? history.replace(route, params)
      : history.push(route, params))
  );
};
