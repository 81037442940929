import React from 'react';
import {
  AddCircleOutline,
  ArrowBackOutline,
  BuildOutline,
  CalendarOutline,
  CallOutline,
  CarOutline,
  ChatboxEllipsesOutline,
  Checkmark,
  CheckmarkCircleOutline,
  CheckmarkDoneCircleOutline,
  CloseOutline,
  CloudUploadOutline,
  CreateOutline,
  DocumentTextOutline,
  EyeOffOutline,
  EyeOutline,
  GitBranchOutline,
  GitMergeOutline,
  HelpOutline,
  HourglassOutline,
  ImageOutline,
  LanguageOutline,
  ListOutline,
  LockClosedOutline,
  LockOpenOutline,
  LogoFacebook,
  LogoInstagram,
  LogOutOutline,
  LogoYoutube,
  MailOutline,
  MenuOutline,
  MoonOutline,
  NotificationsOutline,
  PeopleOutline,
  PersonAddOutline,
  PersonCircleOutline,
  PhonePortraitOutline,
  ReaderOutline,
  ReceiptOutline,
  RefreshOutline,
  SaveOutline,
  SettingsOutline,
  SunnyOutline,
  TextOutline,
  TrainOutline,
  TrashOutline,
  WarningOutline,
} from 'react-ionicons';

import { icons } from '../../constants/icons';

import { Main } from './style';

type Props = {
  color: string;
  size: string;
  name: string;
  style?: React.CSSProperties;
};

const iconMap = {
  [icons.ADD_CIRCLE_OUTLINE]: AddCircleOutline,
  [icons.ARROW_BACK_OUTLINE]: ArrowBackOutline,
  [icons.BUILD_OUTLINE]: BuildOutline,
  [icons.CALENDAR_OUTLINE]: CalendarOutline,
  [icons.CALL_OUTLINE]: CallOutline,
  [icons.CAR_OUTLINE]: CarOutline,
  [icons.CHATBOX_ELLIPSES_OUTLINE]: ChatboxEllipsesOutline,
  [icons.CHECKMARK_OUTLINE]: Checkmark,
  [icons.CHECKMARK_CIRCLE_OUTLINE]: CheckmarkCircleOutline,
  [icons.CHECKMARK_DONE_CIRCLE_OUTLINE]: CheckmarkDoneCircleOutline,
  [icons.CLOSE_OUTLINE]: CloseOutline,
  [icons.CLOUD_UPLOAD_OUTLINE]: CloudUploadOutline,
  [icons.CREATE_OUTLINE]: CreateOutline,
  [icons.DOCUMENT_TEXT_OUTLINE]: DocumentTextOutline,
  [icons.EYE_OFF_OUTLINE]: EyeOffOutline,
  [icons.EYE_OUTLINE]: EyeOutline,
  [icons.FACEBOOK]: LogoFacebook,
  [icons.GIT_BRANCH_OUTLINE]: GitBranchOutline,
  [icons.GIT_MERGE_OUTLINE]: GitMergeOutline,
  [icons.HOUR_GLASS_OUTLINE]: HourglassOutline,
  [icons.IMAGE_OUTLINE]: ImageOutline,
  [icons.INSTAGRAM]: LogoInstagram,
  [icons.LANGUAGE_OUTLINE]: LanguageOutline,
  [icons.LIST_OUTLINE]: ListOutline,
  [icons.LOCK_CLOSED_OUTLINE]: LockClosedOutline,
  [icons.LOCK_OPEN_OUTLINE]: LockOpenOutline,
  [icons.LOG_OUT_OUTLINE]: LogOutOutline,
  [icons.MAIL_OUTLINE]: MailOutline,
  [icons.MENU_OUTLINE]: MenuOutline,
  [icons.MOON_OUTLINE]: MoonOutline,
  [icons.NOTIFICATIONS_OUTLINE]: NotificationsOutline,
  [icons.PEOPLE_OUTLINE]: PeopleOutline,
  [icons.PERSON_ADD_OUTLINE]: PersonAddOutline,
  [icons.PERSON_CIRCLE_OUTLINE]: PersonCircleOutline,
  [icons.PHONE_OUTLINE]: PhonePortraitOutline,
  [icons.READER_OUTLINE]: ReaderOutline,
  [icons.RECEIPT_OUTLINE]: ReceiptOutline,
  [icons.REFRESH_OUTLINE]: RefreshOutline,
  [icons.SAVE_OUTLINE]: SaveOutline,
  [icons.SETTINGS_OUTLINE]: SettingsOutline,
  [icons.SUN_OUTLINE]: SunnyOutline,
  [icons.TEXT_OUTLINE]: TextOutline,
  [icons.TRAIN_OUTLINE]: TrainOutline,
  [icons.TRASH_OUTLINE]: TrashOutline,
  [icons.WARNING_OUTLINE]: WarningOutline,
  [icons.YOUTUBE]: LogoYoutube,
  default: HelpOutline,
};

const Icon = (props: Props): JSX.Element => {
  const { color, size, name, style } = props;
  const SelectedIcon = iconMap[name] || iconMap.default;

  return (
    <Main customSize={size} id="icon" style={style}>
      <SelectedIcon color={color} height={size} width={size} />
    </Main>
  );
};

export default Icon;
