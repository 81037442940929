import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

export const Main = styled.div`
  align-items: center;
  background-color: transparent;
  display: flex;
  justify-content: center;
`;

export const Container = styled.button<{
  color: string;
  isBordered: boolean;
}>`
  background-color: transparent;
  border: ${(props) => (props.isBordered ? '2px solid' : '0 solid')};
  border-color: ${(props) => props.color};
  border-radius: 56px;
  margin: 0;
  padding: ${(props) => (props.isBordered ? '6px' : '0')};
  position: relative;

  &:hover:enabled {
    cursor: pointer;
    box-shadow: ${(props) =>
      `0px ${isMobile || !props.isBordered ? '0px' : '4px'} ${
        props.color
      }`};
    transition: 200ms;
  }

  &:active:enabled {
    position: relative;
    top: 2px;
  }

  &:disabled {
    opacity: 0.5;
  }
`;

export const Badge = styled.div`
  position: absolute;
  transform: translate(80%, -50%);
  color: white;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  background-color: ${(props) => props.theme.danger};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  pointer-events: none;
  font-weight: bold;
`;

export const MobileBadge = styled.div<{ customSize: number }>`
  position: absolute;
  transform: translate(50%, -50%);
  border-radius: 50%;
  height: ${(props) => `${props.customSize}px`};
  width: ${(props) => `${props.customSize}px`};
`;
