import styled from 'styled-components';

type Props = { color: string; displayPointer?: boolean };

export const PageTitle = styled.h1`
  color: ${(props: Props) => props.color};
  font-family: Roboto;
  margin: 0;
  padding: 0;
  text-align: center;
`;

export const Title = styled.h2`
  color: ${(props: Props) => props.color};
  font-family: Roboto;
  margin: 0;
  padding: 0;
  text-align: center;
`;

export const SubTitle = styled.h4`
  color: ${(props: Props) => props.color};
  font-family: Roboto;
  font-size: 16px;
  margin: 0;
  padding: 0;
`;

export const Legend = styled.a`
  color: ${(props: Props) => props.color};
  cursor: ${(props: Props) =>
    props.displayPointer ? 'pointer' : 'default'};
  font-family: Roboto;
  font-style: italic;
  font-size: 16px;
  margin: 0;
  padding: 0;
  text-decoration-line: underline;
`;
