import React, { ReactElement } from 'react';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Theme } from '../../../theme';
import CustomPageTitle from '../../components/texts/CustomPageTitle';
import CustomTitle from '../../components/texts/CustomTitle';
import { icons } from '../../constants/icons';
import { NEXTBIG } from '../../constants/version';
import { getTranslationFile } from '../../utils/i18n';
import { Version } from '../../types/version';
import VersionsLaptop from './VersionsLaptop';
import VersionsMobile from './VersionsMobile';
import { size } from '../../constants/screenSizes';
import { useWindowDimensions } from '../../utils/useWindowDimensions';

import { GridsContainer, Legend, Legends, Main } from './style';

type Props = {
  theme: Theme;
};

const Versions = ({ theme }: Props): ReactElement => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  const getColor = (versionToCompare: string) => {
    if (!versionToCompare || versionToCompare === '0.0.0')
      return theme.text;
    const v1Parts = process.env
      .REACT_APP_VERSION!.split('.')
      .map((part) => parseInt(part));
    const v2Parts = versionToCompare
      .split('.')
      .map((part) => parseInt(part));
    const indexOfDifference = v1Parts.findIndex(
      (part, index) => part !== v2Parts[index],
    );
    if (indexOfDifference === -1)
      return v1Parts.length > v2Parts.length
        ? theme.success
        : theme.info2;
    return v1Parts[indexOfDifference] > v2Parts[indexOfDifference]
      ? theme.success
      : theme.info;
  };

  const empty = () => '-';

  const isMerged = (isMerged: boolean): string =>
    isMerged ? icons.GIT_MERGE_OUTLINE : icons.GIT_BRANCH_OUTLINE;

  const getVersion = (version: string): string => {
    if (version.includes(NEXTBIG))
      return version.replace(NEXTBIG, '');
    else if (version === '0.0.0') return empty();
    return version;
  };

  return (
    <Main>
      <CustomPageTitle
        color={theme.text}
        style={{ paddingBottom: 40 }}
      >
        {t('VERSIONS.VERSION', {
          version: process.env.REACT_APP_VERSION,
        })}
      </CustomPageTitle>
      <CustomTitle color={theme.text} style={{ paddingBottom: 16 }}>
        {t('VERSIONS.HISTORY')}
      </CustomTitle>
      <Legends>
        <Legend color={theme.info}>
          {t('VERSIONS.FUTURE_VERSION')}
        </Legend>
        <Legend color={theme.info2}>
          {t('VERSIONS.ACTUAL_VERSION')}
        </Legend>
        <Legend color={theme.success}>
          {t('VERSIONS.PUBLISHED_VERSION')}
        </Legend>
      </Legends>
      <GridsContainer>
        {Object.entries(getTranslationFile().VERSIONS.INFO)
          .reverse()
          .map(([key, value]) => {
            const v = value as Version;

            return width >= size.tablet ? (
              <VersionsLaptop
                versionKey={key}
                empty={empty}
                getColor={getColor}
                getVersion={getVersion}
                key={v.DESCRIPTION}
                isMerged={isMerged}
                version={v}
              />
            ) : (
              <VersionsMobile
                versionKey={key}
                empty={empty}
                getColor={getColor}
                getVersion={getVersion}
                key={v.DESCRIPTION}
                version={v}
              />
            );
          })}
      </GridsContainer>
    </Main>
  );
};

export default withTheme(Versions);
