import { t } from 'i18next';

import { Role } from '../types/users';

export const memberRoleTranslation = (role: Role) => {
  switch (role) {
    case Role.MEMBER:
      return t('COMMON.ROLE.MEMBER');
    case Role.OFFICE:
      return t('COMMON.ROLE.OFFICE');
    case Role.ADMIN:
      return t('COMMON.ROLE.ADMIN');
    default:
      return t('COMMON.ROLE.MEMBER');
  }
};
