import React, { ReactElement } from 'react';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Theme } from '../../../theme';
import CustomSubTitle from '../../components/texts/CustomSubTitle';
import CustomTitle from '../../components/texts/CustomTitle';
import { icons } from '../../constants/icons';
import { Event, EventStatus } from '../../types/event';
import { formatDate } from '../../utils/date';
import Tag from '../../components/tag/Tag';
import { capitalizeFirstLetter } from '../../utils/textDecoration';
import Icon from '../../components/icon/Icon';

import {
  Header,
  HeaderButtons,
  HighlightContainer,
  OpenerContainer,
  SessionContainer,
  SessionText,
  Status,
} from './style';

type Props = {
  anchor: string;
  event: Event;
  renderAssignButton: (event: Event) => ReactElement;
  renderOfficeAdminEventManagingButton: (
    event: Event,
  ) => ReactElement | null;
  statusToDisplay: (
    status: EventStatus,
    date: number,
  ) => { color: string; text: string };
  theme: Theme;
};

const PlanningCard = (props: Props): ReactElement => {
  const {
    anchor,
    event,
    renderAssignButton,
    renderOfficeAdminEventManagingButton,
    statusToDisplay,
    theme,
  } = props;

  const { t } = useTranslation();

  return (
    <SessionContainer
      highlight={event.uid === anchor}
      key={event.uid}
      id={event.uid}
    >
      {event.uid === anchor ? (
        <HighlightContainer>
          <Icon
            color={theme.invertedText}
            size={'24px'}
            name={icons.EYE_OUTLINE}
          />
        </HighlightContainer>
      ) : null}
      <Header>
        <CustomTitle
          color={theme.text}
          style={{
            flex: 1,
            textAlign: 'center',
            paddingLeft: 8,
            paddingRight: 8,
            marginBottom: 8,
            textDecoration:
              event.status === EventStatus.Canceled
                ? 'line-through'
                : 'none',
          }}
        >
          {t('PLANNING.SESSION_DATE', {
            date: formatDate(event.date, 'dd/MM/yyyy'),
          })}
        </CustomTitle>
        <HeaderButtons>
          {renderAssignButton(event)}
          {renderOfficeAdminEventManagingButton(event)}
        </HeaderButtons>
      </Header>
      {event.status === EventStatus.Canceled && event.cancelInfo ? (
        <CustomTitle color={theme.text} style={{ textAlign: 'left' }}>
          {`${t('PLANNING.CANCEL_REASON', {
            info: capitalizeFirstLetter(event.cancelInfo),
          })} ⚠️`}
        </CustomTitle>
      ) : null}
      <OpenerContainer>
        <SessionText
          isCanceled={event.status === EventStatus.Canceled}
        >
          {t('PLANNING.OPENER')}
        </SessionText>
        {event.assignedMember &&
        event.status !== EventStatus.Canceled ? (
          <Tag
            color={theme.text}
            text={event.simplifiedAssignedMember}
            userUid={event.assignedMember}
          />
        ) : null}
      </OpenerContainer>
      <SessionText isCanceled={event.status === EventStatus.Canceled}>
        {t('PLANNING.THEME_INFO', {
          theme: capitalizeFirstLetter(event.theme),
        })}
      </SessionText>
      <SessionText isCanceled={event.status === EventStatus.Canceled}>
        {t('PLANNING.NOTE_INFO', {
          note: capitalizeFirstLetter(event.note),
        })}
      </SessionText>
      <Status color={statusToDisplay(event.status, event.date).color}>
        <CustomSubTitle
          color={theme.text}
          style={{
            padding: '8px 24px',
            backgroundColor: theme.classicButton,
            borderRadius: 4,
          }}
        >
          {statusToDisplay(event.status, event.date).text}
        </CustomSubTitle>
      </Status>
    </SessionContainer>
  );
};

export default withTheme(PlanningCard);
