import React, { ReactElement, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory, useLocation } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useAuthState } from 'react-firebase-hooks/auth';

import { Theme } from '../../../theme';
import { goTo, ROUTES } from '../../router/routes';
import { icons } from '../../constants/icons';
import IconButton from '../iconButton/IconButton';
import TextIconButton from '../textIconButton/TextIconButton';
import { auth } from '../../firebase/connection';
import CustomTitle from '../texts/CustomTitle';
import {
  deleteNotification,
  listenToNotifications,
  listenToNotificationsNumbers,
  updateNotificationIsRead,
} from '../../firebase/notifications';
import NotificationModal from './NotificationModal';
import { Notifications } from '../../types/notifications';

import { Container, HeaderRight, Main } from './style';

type Props = {
  easterEgg: {
    toggler: () => void;
    isEasterEggActivate: boolean;
  };
  theme: Theme;
};

const Header = (props: Props): ReactElement => {
  const { easterEgg, theme } = props;

  const { t } = useTranslation();
  const [user] = useAuthState(auth);

  const [notificationsNumber, setNotificationsNumber] =
    useState<number>(0);
  const [notifications, setNotifications] = useState<Notifications>(
    [],
  );
  const [isNotificationsModalOpen, setIsNotificationsModalOpen] =
    useState(false);

  const history = useHistory();
  const location = useLocation();
  const goBack = () => history.goBack();
  const goToLogIn = () => goTo(location, history, ROUTES.LOGIN);
  const goToMenu = () => goTo(location, history, ROUTES.MENU.MAIN);
  const goToVisitor = () => goTo(location, history, ROUTES.HOME);
  const goToNotifications = () =>
    goTo(location, history, ROUTES.NOTIFICATIONS);

  const isNotUserFinalization =
    location.pathname !== ROUTES.USER_FINALIZATION;

  useEffect(() => {
    let notifsNumber: any;

    try {
      notifsNumber = listenToNotificationsNumbers(
        user!.uid,
        (nb: number) => {
          setNotificationsNumber(nb);
        },
      );
    } catch (e) {
      // TODO handleError func
    }

    return () => {
      if (notifsNumber) notifsNumber();
    };
  }, [user]);

  useEffect(() => {
    let notifs: any;

    try {
      notifs = listenToNotifications(
        user!.uid,
        (notifs: Notifications) => {
          setNotifications(notifs);
        },
        6,
      );
    } catch (e) {
      // TODO handleError func
    }

    return () => {
      if (notifs) notifs();
    };
  }, [user]);

  const onIsNotificationsModalOpen = () => {
    setIsNotificationsModalOpen(true);
  };
  const onIsNotificationsModalClose = () =>
    setIsNotificationsModalOpen(false);

  const handleCheckNotification = async (notificationId: string) => {
    await updateNotificationIsRead(notificationId);
  };

  const handleDeleteNotification = async (notificationId: string) => {
    await deleteNotification(notificationId);
  };

  const headerLeftMobile = (): ReactElement => {
    return (
      <IconButton
        iconName={icons.ARROW_BACK_OUTLINE}
        onClick={goBack}
        color={theme.text}
        style={{ justifyContent: 'flex-start', width: 64 }}
      />
    );
  };
  const headerRightMobile = (): ReactElement => {
    return (
      <HeaderRight>
        {user && isNotUserFinalization ? (
          <IconButton
            badge={notificationsNumber}
            color={theme.text}
            disabled={location.pathname === ROUTES.NOTIFICATIONS}
            onClick={goToNotifications}
            iconName={icons.NOTIFICATIONS_OUTLINE}
          />
        ) : null}
        {user ? (
          <IconButton
            iconName={icons.MENU_OUTLINE}
            onClick={goToMenu}
            color={theme.text}
            disabled={
              location.pathname === ROUTES.USER_FINALIZATION ||
              location.pathname === ROUTES.MENU.MAIN
            }
          />
        ) : (
          <IconButton
            iconName={icons.PERSON_CIRCLE_OUTLINE}
            onClick={goToLogIn}
            color={theme.text}
          />
        )}
      </HeaderRight>
    );
  };

  const headerRightOther = (): ReactElement => {
    return (
      <HeaderRight>
        <TextIconButton
          color={
            easterEgg.isEasterEggActivate
              ? theme.text
              : theme.invertedText
          }
          iconName={icons.TRAIN_OUTLINE}
          onClick={easterEgg.toggler}
          title={t('COMMON.EASTER_EGG_BUTTON')}
        />
        {user && isNotUserFinalization ? (
          <IconButton
            badge={notificationsNumber}
            color={theme.text}
            disabled={location.pathname === ROUTES.NOTIFICATIONS}
            helpText={t('COMMON.NOTIFICATIONS')} //TODO fix tooltip reopen on close modal ??
            isbordered={true}
            onClick={onIsNotificationsModalOpen}
            iconName={icons.NOTIFICATIONS_OUTLINE}
          />
        ) : null}
        {user ? (
          <TextIconButton
            color={theme.text}
            disabled={
              location.pathname === ROUTES.USER_FINALIZATION ||
              location.pathname === ROUTES.MENU.MAIN
            }
            iconName={icons.MENU_OUTLINE}
            onClick={goToMenu}
            title={t('COMMON.MENU')}
          />
        ) : (
          <TextIconButton
            color={theme.text}
            iconName={icons.PERSON_CIRCLE_OUTLINE}
            onClick={goToLogIn}
            title={t('COMMON.LOGIN')}
          />
        )}
      </HeaderRight>
    );
  };

  return isMobile ? (
    <Main>
      <Container>
        {headerLeftMobile()}
        <CustomTitle
          color={props.theme.text}
          onClick={() => goToVisitor()}
        >
          {t('COMMON.RCM_SHORT')}
        </CustomTitle>
        {headerRightMobile()}
      </Container>
    </Main>
  ) : (
    <Main>
      <Container>
        <CustomTitle
          color={props.theme.text}
          onClick={() =>
            isNotUserFinalization ? goToVisitor() : null
          }
          style={{
            whiteSpace: 'nowrap',
            cursor: isNotUserFinalization ? 'pointer' : 'default',
          }}
        >
          {t('COMMON.RCM_SHORT')}
        </CustomTitle>
        {headerRightOther()}
      </Container>
      <NotificationModal
        checkNotification={handleCheckNotification}
        deleteNotification={handleDeleteNotification}
        isNotificationsModalOpen={isNotificationsModalOpen}
        onIsNotificationsModalClose={onIsNotificationsModalClose}
        notifications={notifications}
        goToNotifications={goToNotifications}
      />
    </Main>
  );
};

export default withTheme(Header);
