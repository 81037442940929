import {
  createUserWithEmailAndPassword,
  getAuth,
} from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  increment,
  onSnapshot,
  orderBy,
  query,
  runTransaction,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';

import {
  BaseUser,
  BaseUsers,
  Role,
  User,
  Users,
} from '../types/users';
import { getTimestampFromStrDate } from '../utils/date';
import { db, storage } from './connection';
import { ToastLevels, displayToast } from '../utils/Toast';
import { TOAST_LEVELS } from '../constants/toastLevels';
import { generateTempPassword } from '../utils/generateTMPPassword';
import { getDatabaseKeyForEnv } from '../utils/changeConfigWithEnv';
import { IMG_SIZE_LIMIT } from '../constants/imgSizeLimit';
import {
  getNextAssignedEventsIdAndDate,
  unsuscribeMemberToSession,
} from './events';
import { getVolatileStorageValue } from '../utils/useLocalStorage';
import { errorCodes } from '../types/errors';

export const AddBaseUser = async (infoModale: any) => {
  try {
    const uid = uuidv4();
    const tmpPassword = generateTempPassword();

    const docRef = doc(db, 'baseUsersList', uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) throw new Error('already exist'); // TODO trad, voir si pas débile car uuid au dessus

    await createUser(infoModale.email, tmpPassword);

    const baseUser: BaseUser = {
      firstName: infoModale.firstName.toLowerCase(),
      lastName: infoModale.lastName.toLowerCase(),
      uid: uid,
      memberSince: getTimestampFromStrDate(),
      email: infoModale.email,
      tmpPassword: tmpPassword,
      isWelcomeEmailSent: 0,
    };

    await setDoc(docRef, baseUser);
  } catch (error: any) {
    throw new Error(error);
  }
};

const createUser = async (email: string, tmpPassword: string) => {
  try {
    const secondaryApp = initializeApp(
      getDatabaseKeyForEnv(),
      'Secondary',
    );
    const tmpAuth = getAuth(secondaryApp);

    await createUserWithEmailAndPassword(tmpAuth, email, tmpPassword);

    await tmpAuth.signOut();
  } catch (error: any) {
    throw new Error(error);
  }
};

export const baseUserUpdateWelcomeEmailSent = async (
  userUuid: string,
  isWelcomeEmailSent: number,
) => {
  const docRef = doc(db, 'baseUsersList', userUuid);
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) throw new Error(errorCodes.UserNotFound);

  await updateDoc(docRef, {
    isWelcomeEmailSent,
  });
}; //TODO try catch

export const firstConnectionProcess = async (user: any) => {
  const docRef = doc(db, 'users', user.uid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) return;
  const baseUser: BaseUser = await getBaseUserByEmail(user.email);
  const activatedUser: User = {
    disabled: 0,
    firstName: baseUser ? baseUser.firstName : '',
    lastName: baseUser ? baseUser.lastName : '',
    uid: user.uid,
    tmpUidNotToUse: baseUser.uid,
    creationTime: getTimestampFromStrDate(),
    isFirstConnection: 0,
    nbOpenedSessions: 0,
    role: Role.MEMBER,
    phoneNumber: baseUser?.phoneNumber ?? '',
    smartphoneNumber: baseUser?.smartphoneNumber ?? '',
    memberSince: baseUser
      ? baseUser.memberSince
      : getTimestampFromStrDate(),
    email: user.email,
  };
  await setDoc(docRef, activatedUser);
}; //TODO try catch

export const userValidation = async (
  uid: string | undefined,
  updatedUserInfo: Partial<User>,
) => {
  if (!uid) return;
  const docRef = doc(db, 'users', uid);
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) throw new Error(errorCodes.UserNotFound);
  const data = docSnap.data() as User;

  await updateDoc(docRef, {
    firstName: updatedUserInfo.firstName?.toLocaleLowerCase(),
    isFirstConnection: getTimestampFromStrDate(),
    lastName: updatedUserInfo.lastName?.toLocaleLowerCase(),
    phoneNumber: updatedUserInfo.phoneNumber,
    smartphoneNumber: updatedUserInfo.smartphoneNumber,
    memberSince: updatedUserInfo.memberSince,
  });
  await deleteBaseUserDocument(data.tmpUidNotToUse);
}; //TODO try catch

export const getUserInfo = async (userId: any): Promise<User> => {
  if (!userId) throw new Error(errorCodes.InvalidUser);
  try {
    const docRef = doc(db, 'users', userId);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) throw new Error(errorCodes.UserNotFound);

    const data = docSnap.data() as User;

    return {
      creationTime: data?.creationTime,
      firstName: data?.firstName,
      isFirstConnection: data?.isFirstConnection,
      lastName: data?.lastName,
      email: data?.email,
      phoneNumber: data?.phoneNumber,
      smartphoneNumber: data?.smartphoneNumber,
      memberSince: data?.memberSince,
      role: data?.role,
      uid: data?.uid,
      tmpUidNotToUse: data?.tmpUidNotToUse,
      nbOpenedSessions: data?.nbOpenedSessions,
      disabled: data?.disabled,
    };
  } catch (error: any) {
    throw new Error(error);
  }
};

export const listenUserInfo = (
  userId: string,
  onUpdate: (userInfo: User) => void,
): (() => void) => {
  //TODO throw if no userId
  const docRef = doc(db, 'users', userId);

  const unsubscribe = onSnapshot(docRef, (docSnap) => {
    const data = docSnap.data() as User;

    const userInfo: User = {
      creationTime: data?.creationTime,
      firstName: data?.firstName,
      isFirstConnection: data?.isFirstConnection,
      lastName: data?.lastName,
      email: data?.email,
      phoneNumber: data?.phoneNumber,
      smartphoneNumber: data?.smartphoneNumber,
      memberSince: data?.memberSince,
      role: data?.role,
      uid: data?.uid,
      tmpUidNotToUse: data?.tmpUidNotToUse,
      nbOpenedSessions: data?.nbOpenedSessions,
      disabled: data?.disabled,
    };

    onUpdate(userInfo);
  });

  return unsubscribe;
}; //TODO try catch

export const getUserFullName = async (
  userUid: string,
): Promise<string> => {
  try {
    if (!userUid) {
      throw new Error(errorCodes.InvalidUser);
    }
    const docRef = doc(db, 'users', userUid);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data() as User;

    if (data?.firstName && data?.lastName)
      return `${data.firstName} ${data.lastName}`;
    return '';
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getUserRole = async (userUid: string): Promise<Role> => {
  if (!userUid) {
    throw new Error(errorCodes.InvalidUser);
  }
  try {
    const docRef = doc(db, 'users', userUid);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data() as User;

    return data?.role || '';
  } catch (error: any) {
    throw new Error(error);
  }
};

export const uploadUserAvatar = (
  file: Blob,
  userUid: string,
  successMessage: string,
) => {
  if (!userUid) throw new Error(errorCodes.InvalidUser);
  try {
    if (!file) throw new Error(errorCodes.AvatarFileRequired);
    if (file.size > IMG_SIZE_LIMIT.AVATAR)
      throw new Error(errorCodes.AvatarFileOversize);

    const storageRef = ref(storage, `avatars/${userUid}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      null,
      () => {
        throw new Error(errorCodes.AvatarFileUploadFail);
      },
      () =>
        displayToast(
          TOAST_LEVELS.SUCCESS as ToastLevels,
          successMessage,
        ),
    );
  } catch (error: any) {
    throw new Error(error);
  }
};

export const deleteUserAvatar = async (userUid: string) => {
  try {
    if (!userUid) {
      throw new Error(errorCodes.InvalidUser);
    }
    const storageRef = ref(storage, `avatars/${userUid}`);

    await deleteObject(storageRef);
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getUserAvatar = async (
  userUid: string,
): Promise<string> => {
  try {
    const isEasterEggAvatarActivated = getVolatileStorageValue(
      'isEasterEggAvatarActivated',
      false,
    );
    if (isEasterEggAvatarActivated) {
      return require(`../../assets/images/easterEggDevAvatar.gif`);
    }
  } catch (error: any) {}

  try {
    if (!userUid) {
      throw new Error(errorCodes.InvalidUser);
    }
    const storageRef = ref(storage, `avatars/${userUid}`);
    const avatarURL = await getDownloadURL(storageRef);
    return avatarURL;
  } catch (error: any) {
    if (error.code === 'storage/object-not-found') return '';
    else throw new Error(error);
  }
};

export const listenToAllUsers = (
  onUpdate: (users: Users) => void,
  shouldGetDisabledUsers?: boolean,
) => {
  const q = shouldGetDisabledUsers
    ? query(collection(db, 'users'), orderBy('lastName'))
    : query(
        collection(db, 'users'),
        where('disabled', '==', 0),
        orderBy('lastName'),
      );

  const unsubscribe = onSnapshot(
    q,
    (querySnapshot) => {
      let users: Users = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        users.push({
          creationTime: data?.creationTime,
          firstName: data?.firstName,
          isFirstConnection: data?.isFirstConnection,
          lastName: data?.lastName,
          email: data?.email,
          phoneNumber: data?.phoneNumber,
          smartphoneNumber: data?.smartphoneNumber,
          memberSince: data?.memberSince,
          role: data?.role,
          uid: data?.uid,
          tmpUidNotToUse: data?.tmpUidNotToUse,
          nbOpenedSessions: data?.nbOpenedSessions,
          disabled: data?.disabled,
        });
      });

      onUpdate(users);
    },
    (error) => {
      console.log('Error listening to user updates:', error);
      // TODO Handle the error with a try catch
    },
  );

  return unsubscribe;
};

export const listenToAllBaseUsersList = (
  onUpdate: (users: BaseUsers) => void,
) => {
  const unsubscribe = onSnapshot(
    collection(db, 'baseUsersList'),
    (querySnapshot) => {
      let users: BaseUsers = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();

        users.push({
          firstName: data?.firstName,
          lastName: data?.lastName,
          email: data?.email,
          phoneNumber: data?.phoneNumber,
          smartphoneNumber: data?.smartphoneNumber,
          memberSince: data?.memberSince,
          uid: data?.uid,
          tmpPassword: data?.tmpPassword,
          isWelcomeEmailSent: data?.isWelcomeEmailSent,
        });
      });

      onUpdate(users);
    },
  );

  return unsubscribe;
}; //TODO try catch

export const getBaseUserByEmail = async (
  email: string,
): Promise<BaseUser> => {
  const q = query(
    collection(db, 'baseUsersList'),
    where('email', '==', email),
  );
  const querySnapshot = await getDocs(q);

  const doc = querySnapshot.docs[0];
  const data = doc.data();

  return {
    firstName: data?.firstName,
    lastName: data?.lastName,
    email: data?.email,
    phoneNumber: data?.phoneNumber,
    smartphoneNumber: data?.smartphoneNumber,
    memberSince: data?.memberSince,
    uid: data?.uid,
    tmpPassword: data?.tmpPassword,
    isWelcomeEmailSent: data?.isWelcomeEmailSent,
  };
}; //TODO try catch

export const incrementSessionsCounter = async (userUid: string) => {
  if (!userUid) {
    throw new Error(errorCodes.InvalidUser);
  }
  const docRef = doc(db, 'users', userUid);
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) throw new Error(errorCodes.UserNotFound);

  const data = docSnap.data();
  const nbOpenedSessions = data?.nbOpenedSessions ?? 0;

  await updateDoc(docRef, {
    nbOpenedSessions: nbOpenedSessions + 1,
  });
}; //TODO try catch

export const decrementSessionsCounter = async (userUid: string) => {
  if (!userUid) {
    throw new Error(errorCodes.InvalidUser);
  }
  const docRef = doc(db, 'users', userUid);
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) throw new Error(errorCodes.UserNotFound);

  await updateDoc(docRef, {
    nbOpenedSessions: increment(-1),
  });
}; //TODO try catch

export const deleteBaseUserDocument = async (
  tmpBaseUserUid: string,
) => {
  try {
    const docRef = doc(db, 'baseUsersList', tmpBaseUserUid);
    await deleteDoc(docRef);
  } catch (error: any) {
    throw new Error(error);
  }
};

export const togleUserDesactivation = async (
  uid: string,
  disabled: boolean,
) => {
  try {
    const docRef = doc(db, 'users', uid);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) throw new Error(errorCodes.UserNotFound);

    await updateDoc(docRef, {
      disabled: disabled ? getTimestampFromStrDate() : 0,
    });
    const sessionsToUnsuscribe = await getNextAssignedEventsIdAndDate(
      uid,
    );
    sessionsToUnsuscribe.map(async (event) => {
      await unsuscribeMemberToSession(event.id, event.date, uid);
    });
  } catch (error: any) {
    throw new Error(error);
  }
};

export const changeUserEmail = async (
  userId: string,
  newEmail: string,
) => {
  try {
    const docRef = doc(db, 'usersg', userId);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) throw new Error(errorCodes.UserNotFound);

    await updateDoc(docRef, {
      email: newEmail,
    });
  } catch (error: any) {
    throw new Error(error);
  }
};

export const changeUserInfo = async (
  userId: string,
  newPhoneNumber: string,
  newSmartphoneNumber: string,
) => {
  try {
    const docRef = doc(db, 'users', userId);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) throw new Error(errorCodes.UserNotFound);

    await updateDoc(docRef, {
      phoneNumber: newPhoneNumber,
      smartphoneNumber: newSmartphoneNumber,
    });
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getAllUsersUid = async (): Promise<string[]> => {
  try {
    const q = query(
      collection(db, 'users'),
      where('disabled', '==', 0),
    );
    const querySnapshot = await getDocs(q);

    return querySnapshot.docs.map((doc) => doc.data().uid);
  } catch (error) {
    console.log('Error fetching user UIDs:', error);
    return []; // TODO Handle the error
  }
};

export const migrateUsersV2ToV3 = async () => {
  try {
    const usersCollectionRef = collection(db, 'users');
    const usersSnapshot = await getDocs(usersCollectionRef);
    await runTransaction(db, async (transaction) => {
      usersSnapshot.forEach((userDoc) => {
        const userRef = userDoc.ref;

        transaction.update(userRef, {
          disabled: 0,
          tmpUidNotToUse: '',
        });
      });
    });
  } catch (error: any) {
    throw new Error(error);
  }
};
