import { ReactElement, useState } from 'react';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Theme } from '../../../theme';
import { icons } from '../../constants/icons';
import EditionModal from '../../components/editionModal/EditionModal';
import Input from '../../components/input/Input';
import { displayToast, ToastLevels } from '../../utils/Toast';
import { TOAST_LEVELS } from '../../constants/toastLevels';
import Select from '../../components/select/Select';
import { Option } from '../../components/select/style';
import { NotificationType } from '../../types/notifications';
import { handleFirebaseErrors } from '../../utils/errors';
import { errorCodes } from '../../types/errors';
import { newNotification } from '../../firebase/notifications';
import { CustomNotification } from '../../utils/notifications';

type Props = {
  closeSendCustomNotificationModal: () => void;
  isSendCustomNotificationModalOpen: boolean;
  theme: Theme;
};

const SendCustomNotificationModal = (props: Props): ReactElement => {
  const {
    closeSendCustomNotificationModal,
    isSendCustomNotificationModalOpen,
    theme,
  } = props;

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [inputFields, setInputFields] = useState({
    messageFR: '',
    messageEN: '',
    type: 'placeholder',
  });
  const [errors, setErrors] = useState({
    messageFR: '',
    messageEN: '',
    type: '',
  });

  const types: (Partial<NotificationType> | 'placeholder')[] = [
    'placeholder',
    NotificationType.CUSTOM,
    NotificationType.VERSION,
  ];

  const handleChange = (e: any) => {
    setErrors({
      ...errors,
      [e.target.name]: '',
    });
    setInputFields({
      ...inputFields,
      [e.target.name]: e.target.value,
    });
  };

  const close = () => {
    setErrors({
      messageFR: '',
      messageEN: '',
      type: '',
    });
    setInputFields({
      messageFR: '',
      messageEN: '',
      type: 'placeholder',
    });

    closeSendCustomNotificationModal();
  };

  const validateValues = (inputValues: {
    messageFR: string;
    messageEN: string;
    type: string;
  }) => {
    let err = {
      messageFR: '',
      messageEN: '',
      type: '',
    };
    if (!inputValues.type) {
      handleFirebaseErrors(
        errorCodes.NotificationTypeEmpty,
        (x) => (err.type = x),
      );
    }
    if (inputValues.type === 'placeholder') {
      handleFirebaseErrors(
        errorCodes.NotificationTypeEmpty,
        (x) => (err.type = x),
      );
    }
    if (!inputValues.messageFR) {
      handleFirebaseErrors(
        errorCodes.NotificationFREmpty,
        (x) => (err.messageFR = x),
      );
    }
    if (!inputValues.messageEN) {
      handleFirebaseErrors(
        errorCodes.NotificationENEmpty,
        (x) => (err.messageEN = x),
      );
    }

    setErrors(err);
    return err;
  };

  const getCustomContentId = () => {
    if (inputFields.type === NotificationType.VERSION) return 'root';
    return '';
  };

  const translateTypes = (
    type: Partial<NotificationType> | 'placeholder',
  ) => {
    if (type === 'custom') return t('ADMIN.NOTIFICATION_TYPE_CUSTOM');
    if (type === 'placeholder') return '-';
    if (type === 'newVersion')
      return t('ADMIN.NOTIFICATION_TYPE_NEW_VERSION');
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      if (
        Object.values(validateValues(inputFields)).every(
          (err) => err === null || err === '',
        )
      ) {
        await newNotification(
          CustomNotification.getCustomBody(
            inputFields.messageFR,
            inputFields.messageEN,
            inputFields.type as NotificationType,
          ),
          undefined,
          undefined,
          getCustomContentId(),
        );
        displayToast(
          TOAST_LEVELS.SUCCESS as ToastLevels,
          t('ADMIN.NOTIFICATION_SEND_SUCCESS'),
        );
        close();
      }
    } catch (error: any) {
      //TODO handle error silently
    } finally {
      setLoading(false);
    }
  };

  return (
    <EditionModal
      button={{
        color: theme.success,
        iconName: icons.CHECKMARK_OUTLINE,
        text: t('COMMON.SEND'),
        action: onSubmit,
      }}
      closeAction={close}
      ismodalOpen={isSendCustomNotificationModalOpen}
      loading={loading}
      secondaryButton={{
        color: theme.danger,
        iconName: icons.CLOSE_OUTLINE,
        text: t('COMMON.CANCEL'),
        action: close,
      }}
      title={t('ADMIN.SEND_NOTIFICATION')}
    >
      <Select
        id="notification-type"
        color={props.theme.text}
        disabled={loading}
        leftIconName={icons.NOTIFICATIONS_OUTLINE}
        loading={loading}
        error={errors.type}
        name="type"
        onChange={handleChange}
        required={true}
        title={t('ADMIN.SELECT_NOTIFICATION_TYPE')}
        style={{ marginBottom: 48 }}
        value={inputFields.type}
      >
        {types.map((type) => (
          <Option
            backgroundColor={props.theme.background}
            color={props.theme.text}
            key={type}
            value={type}
          >
            {translateTypes(type)}
          </Option>
        ))}
      </Select>
      <Input
        color={theme.text}
        placeholder={t('COMMON.TEXT')}
        title={t('ADMIN.MESSAGE_FR')}
        type="text"
        isTextArea={true}
        disabled={loading}
        error={errors.messageFR}
        charLimit={300}
        onChange={handleChange}
        value={inputFields.messageFR}
        required={true}
        name="messageFR"
      />
      <Input
        color={theme.text}
        placeholder={t('COMMON.TEXT')}
        title={t('ADMIN.MESSAGE_EN')}
        type="text"
        disabled={loading}
        error={errors.messageEN}
        isTextArea={true}
        charLimit={300}
        onChange={handleChange}
        value={inputFields.messageEN}
        required={true}
        name="messageEN"
      />
    </EditionModal>
  );
};

export default withTheme(SendCustomNotificationModal);
