import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

import { headerSize } from '../../constants/screenSizes';

export const Main = styled.div`
  align-items: center;
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: ${headerSize.style};
  min-height: calc(100vh - 90px - 8rem); //TODO remove wierd rem
  padding: ${isMobile ? '56px 16px' : '56px 48px'};
`;
