import styled from 'styled-components';

import {
  device,
  headerSize,
  size,
} from '../../constants/screenSizes';

export const Main = styled.header`
  align-items: center;
  background-color: ${(props) => props.theme.background};
  border-bottom: ${(props) =>
    `1px solid ${props.theme.invertedBackground}`};
  display: flex;
  flex-shrink: 0 !important;
  font-size: calc(8px + 2vmin);
  height: ${headerSize.style};
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1100;
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  width: 100%;

  @media ${device.laptop} {
    max-width: ${() => size.laptop}px;
  }
`;

export const HeaderRight = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const ModalCore = styled.div`
  align-items: center;
  align-content: center;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  flex-direction: column;
  gap: 8px;
`;

export const NotificationCards = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 16px;
  width: 100%;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  -webkit-scrollbar {
    display: none; /* Safari, Chrome and Edge */
  }
`;

export const NotificationCard = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.classicButton};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
  padding: 8px 16px 8px 24px;
  position: relative;
`;

export const NotificationCore = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const NotificationFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const NotficationFooterButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const Info = styled.p<{ italic?: boolean }>`
  color: ${(props) => props.theme.text};
  font-style: ${(props) => (props.italic ? 'italic' : 'none')};
  margin: 0;
  text-align: left;
`;

export const ReadStatus = styled.div`
  background-color: ${(props) => props.theme.info};
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  width: 8px;
`;
