import { getFixedT } from 'i18next';

import {
  NotificationBody,
  NotificationType,
} from '../types/notifications';
import { formatDate } from './date';
import {
  capitalizeFirstLetter,
  capitalizeFirstLetterForEachWord,
} from './textDecoration';
import { Language } from '../../i18n';
import { goTo, ROUTES } from '../router/routes';
import { updateNotificationIsRead } from '../firebase/notifications';

const getRouteByNotificationType = (
  type: NotificationType,
): string => {
  switch (type) {
    case NotificationType.BLOG:
      return ROUTES.BLOG.MAIN;
    case NotificationType.PLANNING:
      return ROUTES.MENU.PLANNING;
    case NotificationType.VERSION:
      return ROUTES.MENU.SETTINGS.VERSIONS;
    default:
      return '';
  }
};

export const goToTargetContent = async (
  type: NotificationType,
  notificationId: string,
  alreadyRead: number,
  history: any,
  location: any,
  targetContentId?: string,
) => {
  if (alreadyRead === 0)
    await updateNotificationIsRead(notificationId);
  if (!targetContentId) return;
  const route = getRouteByNotificationType(type);

  if (targetContentId === 'root') goTo(location, history, route);
  else goTo(location, history, `${route}#${targetContentId}`);
};

export class CustomNotification {
  public static getAssignMemberToSessionBody(
    userName: string,
    sessionDate: number,
  ): NotificationBody {
    const date = formatDate(sessionDate, 'dd/MM/yyyy');
    const name = capitalizeFirstLetterForEachWord(userName);
    const fr = getFixedT(Language.FR);
    const en = getFixedT(Language.EN);

    return {
      messageFR: fr('NOTIFICATION.ASSIGN_MEMBER', {
        name,
        date,
      }),
      messageEN: en('NOTIFICATION.ASSIGN_MEMBER', {
        name,
        date,
      }),
      type: NotificationType.PLANNING,
    };
  }

  public static getUnassignMemberToSessionBody(
    userName: string,
    sessionDate: number,
  ): NotificationBody {
    const date = formatDate(sessionDate, 'dd/MM/yyyy');
    const name = capitalizeFirstLetterForEachWord(userName);
    const fr = getFixedT(Language.FR);
    const en = getFixedT(Language.EN);

    return {
      messageFR: fr('NOTIFICATION.UNASSIGN_MEMBER', {
        name,
        date,
      }),
      messageEN: en('NOTIFICATION.UNASSIGN_MEMBER', {
        name,
        date,
      }),
      type: NotificationType.PLANNING,
    };
  }

  public static getCancelSessionBody(
    userName: string,
    sessionDate: number,
  ): NotificationBody {
    const date = formatDate(sessionDate, 'dd/MM/yyyy');
    const name = capitalizeFirstLetterForEachWord(userName);
    const fr = getFixedT(Language.FR);
    const en = getFixedT(Language.EN);

    return {
      messageFR: fr('NOTIFICATION.CANCEL_SESSION', {
        name,
        date,
      }),
      messageEN: en('NOTIFICATION.CANCEL_SESSION', {
        name,
        date,
      }),
      type: NotificationType.PLANNING,
    };
  }

  public static getRevertCancelSessionBody(
    userName: string,
    sessionDate: number,
  ): NotificationBody {
    const date = formatDate(sessionDate, 'dd/MM/yyyy');
    const name = capitalizeFirstLetterForEachWord(userName);
    const fr = getFixedT(Language.FR);
    const en = getFixedT(Language.EN);

    return {
      messageFR: fr('NOTIFICATION.REVERT_CANCEL_SESSION', {
        name,
        date,
      }),
      messageEN: en('NOTIFICATION.REVERT_CANCEL_SESSION', {
        name,
        date,
      }),
      type: NotificationType.PLANNING,
    };
  }

  public static getWelcomeBody(
    userFirstName: string,
  ): NotificationBody {
    const firstName = capitalizeFirstLetter(userFirstName);
    const fr = getFixedT(Language.FR);
    const en = getFixedT(Language.EN);

    return {
      messageFR: fr('NOTIFICATION.WELCOME', {
        firstName,
      }),
      messageEN: en('NOTIFICATION.WELCOME', {
        firstName,
      }),
      type: NotificationType.WELCOME,
    };
  }

  public static getCustomBody(
    messageFR: string,
    messageEN: string,
    type: NotificationType,
  ): NotificationBody {
    return {
      messageFR,
      messageEN,
      type,
    };
  }
}
