import styled from 'styled-components';
import { footerSize } from '../../constants/screenSizes';

export const Main = styled.footer`
  align-items: center;
  display: flex;
  flex-shrink: 0 !important;
  height: ${footerSize.style};
  justify-content: center;
  width: 100%;
  z-index: 100;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 30% 40% 30%;
  height: ${footerSize.style};
  padding: 8px 24px;
  width: 100%;
`;

export const GridContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: space-around;
  flex-direction: row;
`;

export const FooterText = styled.p`
  margin: 0;
  padding: 0;
`;
